import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../fetch/axios"
import { useSnackbar } from "notistack";

const RegistroExitoso = () => {

  const { token } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [name,setName] =useState(null)
  
  useEffect(()=>{

    if(token && !localStorage.getItem('token_arteonu')){

      const fetchConfirmation = async () => {

        try{
          const res = await axios.put(
            "/users/complete-register ",
            {
              "token": token,
            }
          );
  
          if (res.data.errors) {
            enqueueSnackbar(res.data.message, { variant: "error" });
          } else {
            let user ={
              "id": res.data.id,
              "name": res.data.name,
              "email": res.data.email,
              "api_token": "64|xvRTz5L9I07hAqVMFkj8mt0WBVRS38p3WAurRYr16232dbc6",
              "phone": res.data.phone,
              "affiliation": res.data.affiliation
            }
            localStorage.setItem('token_arteonu',res.data.api_token)
            localStorage.setItem('user_arteonu', JSON.stringify(user));
            localStorage.removeItem('token_volver_crear')
            localStorage.removeItem('email_crear')
            
            setName(res.data.name)
          }

        } catch (error) {
          enqueueSnackbar(error.response.data, { variant: "error" });
        }
      }
      
      fetchConfirmation()
    }
  },[])

  return (
    <main>
      <section className="AgregadoCarrito">
        <div>
          <div className="icon-Check"></div>
          <h3>Registro exitoso</h3>
          <em>{name ?? ''} ¡Bienvenido a ArteOnU!</em>
          <div className="ButtonDarkMedium" onClick={() => navigate("/home")}>
            <i className="icon-Trolley"></i>
            VER TIENDA
          </div>
          <div
            className="ButtonLightMedium"
            onClick={() => navigate("/mi-perfil")}
          >
            VER MI PERFIL
          </div>
        </div>
      </section>
      
      {/* <PodriaInteresarte title="Quizá puede interesarte" related={productos} /> */}
      
    </main>
  );
};

export default RegistroExitoso;
