import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import PreviewItemCarrito from "./PreviewItemCarrito";
import { CartContext } from "../../context/CardContext";
const Component = ({ carritoinfo = {} }) => {

  const navigate = useNavigate();

  const producto = JSON.parse(localStorage.getItem('addPrevent'));
  const { totalQuantity,totalPay } = useContext(CartContext);
  
  const remove= ()=>{
    navigate("/detalles-producto/" + producto.uri)
  }

  return (
    <>
      <section className="AgregadoCarrito">
        <div>
          <div className="icon-Check"></div>
          <h3>
            Producto agregado
            <br /> al carrito
          </h3>
          {<PreviewItemCarrito producto={producto} />}
          <p className="FontMedium">
            Hay {totalQuantity} artículos en tu carrito con un total de:
            ${totalPay}
          </p>

          <div
            className="ButtonDarkMedium"
            onClick={() => navigate("/carrito")}
          >
            <i className="icon-Trolley"></i>
            VER CARRITO
          </div>
          <div
            className="ButtonLightMedium"
            onClick={() => {remove()}}
          >
            SEGUIR COMPRANDO
          </div>
        </div>
      </section>
    </>
  );
};

export default Component;
