import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "../../fetch/axios";


const Component = () => {

  const navigate = useNavigate();
  const [name,setName] = useState(null);
  const [email,setEmail] = useState(null);
  const [password,setPassword] = useState(null);
  const [phone,setPhone] = useState(null);
  const [country,setCountry] = useState(142);
  const { enqueueSnackbar } = useSnackbar();
  
  const TogglePassword = (event) => {
    event = event.target;
    event.classList.toggle("icon-EyeClosed");
    event.classList.toggle("icon-EyeOpen");
    event = event.previousElementSibling;
    if (event.toggleAttribute("type")) {
      event.type = "password";
    }
  };

  const registrarse = async ()=>{

    if(!name  || !email || !password || !phone ){
      enqueueSnackbar("Faltan campos por llenar", { variant: "error" });
      return;
    }

    try {
      const res = await axios.post(
        "/users/sign-in",
        {
          "name": name,
          "country_id": country,
          "phone": phone,
          "email": email,
          "password": password
        }
      );
      if (res.data.errors) {
        enqueueSnackbar(res.data.message, { variant: "error" });
      } else {
        localStorage.setItem('token_volver_crear',res.data.token)
        localStorage.setItem('email_crear',email)
        navigate("/login-confirmar-registro")
        enqueueSnackbar("Mensaje reenviado con éxito", { variant: "success" });
      }
    } catch (error) {
      enqueueSnackbar(error.response.data, { variant: "error" });
    }
    
  }

  return (
    <>
      <label className="FormData">
        <em className="RequiredData">Nombre</em>
        <div className="FormInput">
          <input placeholder="Nombre completo según identificación oficial" onChange={(e)=>{setName(e.target.value)}}/>
        </div>
      </label>
      <div className="Row">
        <label className="FormData">
          <em className="RequiredData">Pais</em>
          <div className="FormInput">
            <select placeholder="Email" onChange={(e)=>{setCountry(e.target.value)}}>
              <option value="142" selected>
                México
              </option>
              <option value="233">USA</option>
            </select>
            <i className="InputIcon icon-Select"></i>
          </div>
        </label>
        <label className="FormData">
          <em className="RequiredData">Número de móvil</em>
          <div className="FormInput">
            <input placeholder="5500000000" onChange={(e)=>{setPhone(e.target.value)}}/>
          </div>
        </label>
      </div>
    
      <label className="FormData">
        <em className="RequiredData">Email</em>
        <div className="FormInput">
          <input placeholder="Email" onChange={(e)=>{setEmail(e.target.value)}}/>
        </div>
      </label>
      <label className="FormData">
        <em className="RequiredData">Contraseña</em>
        <div className="FormInput">
          <input placeholder="Contraseña" type="password" onChange={(e)=>{setPassword(e.target.value)}}/>
          <i className="InputIcon icon-EyeClosed" onClick={TogglePassword}></i>
        </div>
      </label>
      <div className="TextColorSec">
        Al hacer click en "Registrarse" aceptas nuestros <Link to="/404">"Términos y Condiciones"</Link>, <Link to="/404">"Términos de Uso"</Link>, <Link to="/404">"Términos de Servicio"</Link> y <Link to="/404">"Políticas de Privacidad"</Link>, así como el <Link to="/404">"Aviso de Privacidad"</Link>.
      </div>
      <div
        className="ButtonLightMedium"
        onClick={() => registrarse()}
      >
        REGISTRARSE
        <i className="icon-ArrowRight"></i>
      </div>
      <div>
        <p>¿Ya tienes una cuenta?</p>
        <Link to="/login">
          <b>Iniciar sesión</b>
        </Link>
      </div>
      <div className="TextColorSec">
        <em>¿Olvidaste tu contraseña? </em>
        <Link to="/login-recuperar">
          <b>Recuperar contraseña</b>
        </Link>
      </div>
    </>
  );
};

export default Component;
