import React from "react";
import axios from "../../fetch/axios"
import { useSnackbar } from "notistack";

const Component = () => {

  const { enqueueSnackbar } = useSnackbar();
  const token_recuperar_clave = localStorage.getItem("token_recuperar_clave");
  const token_email = localStorage.getItem("token_email");

  const reenviar = async (type=1) => {
    try {
      const res = await axios.put(
        "/users/resend-password-recovery",
        {
          "token": token_recuperar_clave,
          "type": type
        }
      );
      if (res.data.errors) {
        enqueueSnackbar(res.data.message, { variant: "error" });
      } else {
        localStorage.setItem('token_recuperar_clave', res.data.token);
        enqueueSnackbar("Mensaje reenviado con éxito", { variant: "success" });
      }
    } catch (error) {
      enqueueSnackbar(error.response.data, { variant: "error" });
    }
  }

  return (
    <>
      <div className="icon-Mail IconLarge"></div>
      <div className="TextLeft">
        <p>
          Hemos enviado un correo electrónico a {token_email}, con un enlace
          para recuperar tu contraseña.
        </p>
        <p>
          Si no encuentras el mensaje en tu bandeja principal, revisa las
          bandejas de spam y promociones.
        </p>
      </div>
      <div>
        <p>¿No recibiste el correo de confirmación?</p>
        <b onClick={()=>{reenviar(1)}}>Reenviar correo</b>
      </div>
      <div>
          <b onClick={()=>{reenviar(2)}}>Enviar SMS</b>
      </div>
    </>
  );
};

export default Component;
