import React from "react";
import { useNavigate, Link } from "react-router-dom";

const FigureProduct = ({ imagedesktop,imagemovile, uri,description = "", }) => {
  const navigate = useNavigate();
  return (
    <div
      key={`${performance.now()}-${Math.random()}`}
      onMouseUp={() => navigate(`/detalles-producto/${uri}`)}
      style={{ "cursor":"pointer" }}
    >
      <figure>
        <picture>
          <source media="(min-width: 768px)" srcSet={imagedesktop} />
          <source media="(max-width: 767px)" srcSet={imagemovile} />
          <img src={imagedesktop} alt={description} />
        </picture>
        <figcaption>{description}</figcaption>
      </figure>
    </div>
  );
};

export default FigureProduct;
