import React from 'react';

const Spinner = () => {

  return (
    <div className={`loading-overlay`}>
      <div className="loading-spinner"></div>
    </div>
  );
};

export default Spinner;

