import React, { useState } from 'react';
import axios from "../../fetch/axios";
import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';

const CambiarPassword = () => {

	const { enqueueSnackbar } = useSnackbar();
	const [clave,setClave] = useState(null);
	const [confirmar,setConfirmar] = useState(null);

	const { token } = useParams();
	const navigate = useNavigate();
	
	const TogglePassword = (event) => {
		event = event.target;
		event.classList.toggle('icon-EyeClosed');
		event.classList.toggle('icon-EyeOpen');
		event = event.previousElementSibling;
		if (event.toggleAttribute('type')) {
			event.type = 'password';
		}
	};

	const cambiar = async()=>{

		if(!clave || !confirmar){
			enqueueSnackbar("Debe de llenar ambos campos", { variant: "error" });
			return;
		}
		
		if(clave !=confirmar){
			enqueueSnackbar("Las claves no coinciden", { variant: "error" });
			return;
		}
		try {

			const res = await axios.put(
			  "/users/password-reset",
			  {
				token: token,
				password: clave,
				password_confirmation: confirmar
			  }
			);
			if (res.data.errors) {;
			  enqueueSnackbar(res.data.message, { variant: "error" });
			} else {
				localStorage.removeItem('token_email');
				localStorage.removeItem('token_recuperar_clave');
				navigate('/cambiar-contrasena-exito')
			}
		  } catch (error) {
			enqueueSnackbar("Error intentar cambiar clave", { variant: "error" });
		  }
	}
	return (
		<>
			{/* if not <> -> use className='DataBox' */}
			<div className='TextLeft'>
				<p>Ingresa tu nueva contraseña</p>
			</div>
			{/**
			  *
			  * ! InvalidInput className in label-> error input style
			  *
			  **/}
			<label className='FormData'>
				<em className='RequiredData'>Nueva contarseña</em>
				<div className='FormInput'>
					<input placeholder='Nueva contarseña' onChange={(e)=>{setClave(e.target.value)}}
						type='password' />
					<i className='InputIcon icon-EyeClosed' onClick={TogglePassword}></i>
				</div>
				<em className='InfoData'>Contraseña incorrecta</em>
			</label>
			<label className='FormData'>
				<em className='RequiredData'>Repetir nueva contarseña</em>
				<div className='FormInput'>
					<input placeholder='Repetir nueva contarseña' onChange={(e)=>{setConfirmar(e.target.value)}}
						type='password' />
					<i className='InputIcon icon-EyeClosed' onClick={TogglePassword}></i>
				</div>
				<em className='InfoData'>Contraseña incorrecta</em>
			</label>
			<div className='ButtonLightMedium' onClick={cambiar}>
				CAMBIAR CONTRASEÑA
				<i className='icon-ArrowRight'></i>
			</div>
		</>
	);
};

export default CambiarPassword;