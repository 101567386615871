import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import InstagramBox from "../../components/InstagramBox";
import Figure from "../../components/Figure";
import axios from '../../fetch/axios';

const Component = () => {
  const navigate = useNavigate();
  const [banner, setBanner] = useState(null);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [loadingBanner, setLoadingBanner] = useState(true);
  const [loadingCategories, setLoadingCategories] = useState(true);
  const [loadingProducts, setLoadingProducts] = useState(true);

  useEffect(() => {
    const fetchBanner = async () => {
      try {
        const response = await axios.get('/banners/home');
        setBanner(response.data[0]);
      } catch (error) {
        console.error('Error fetching banner', error);
      } finally {
        setLoadingBanner(false);
      }
    };
    fetchBanner();
  }, []);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get('/categories/home');
        setCategories(response.data);
      } catch (error) {
        console.error('Error fetching categories', error);
      } finally {
        setLoadingCategories(false);
      }
    };
    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get('/products/home');
        setProducts(response.data);
      } catch (error) {
        console.error('Error fetching products', error);
      } finally {
        setLoadingProducts(false);
      }
    };
    fetchProducts();
  }, []);

  const RowWithButton = (text, image, link,index) => {
    return (
      <div 
        key={index}
        style={{ backgroundImage: `url(${image})` }}>
        <div className="MainButton">
          <div className="ButtonLight2" onClick={() => navigate(link)}>
            {text}
            <i className="icon-ArrowRight"></i>
          </div>
        </div>
      </div>
    );
  };

  const LoadingCard = ({ cantidad = 4 }) => {
    const generateLoaderDiv = (index) => (
      <div key={index} className="col-md-3">
        <div className="wrapper">
          <div className="card-loader card-loader--tabs"></div>
        </div>
      </div>
    );
  
    return Array(cantidad).fill(0).map((_, index) => generateLoaderDiv(index));
  };
  

  return (
    <section>
      
      {/* Banner */}
      {/* <article className="MainImageBox" style={{ backgroundImage: `url(${banner ? banner.desktop_image : ''})` }}> */}
      <article className="MainImageBox" >
        <div className="heroImage">
          <picture>
            <source media="(max-width: 768px)" srcSet={banner ? banner.mobile_image : ''} />
            <img src={banner ? banner.desktop_image : ''} alt="Banner" />
          </picture>
        </div>
        {loadingBanner ? (
          <div className="MainBox">
            <LoadingCard cantidad={1} />
          </div>
        ) : banner && (
          <div className="MainButton">
            <div className="ButtonLight2">
            <a href="#NewArrivals" className="link">
              {banner.button}
              <i className="icon-ArrowRight"></i>
            </a>

            </div>
          </div>
        )}
      </article>

      {/* Categorias */}
      <article className="MainBox">
        <div>
          <div className="MainContentItems MCtemsButtons">
            {loadingCategories ? LoadingCard(4) : categories.map((category,index) => (
              RowWithButton(category.name, category.desktop_image, `/category/${category.uri}`,index)
              
            ))}
          </div>
        </div>
      </article>

      {/* Contacto */}
      <article className="Sticky">
        <div
          className="ButtonContent ButtonDark"
          onClick={() => navigate("/contacto")}
        >
          Contact Us
        </div>
      </article>

      {/* Publicaciones Instagram */}
      <article className="MainBox">
        <div>
          <div className="WeCreateToBeYourself"></div>
          <InstagramBox />
        </div>
      </article>

      {/* Imagenes Figure */}
      <article id="NewArrivals" className="MainBox">
        <div>
          <div className="NewArrivals"></div>
          <div className="MainContentItems">
          {loadingProducts 
            ? <LoadingCard loading={true} />
            : products
                .filter(Boolean)
                .map((product, index) => (
                  <Figure key={index} image={product.desktop_image} uri={product.uri} />
                ))
          }

          </div>
        </div>
      </article>
      
      <article className="AboutBox">
        <div className="AboutUsBox">
          <div className="AboutUs"></div>
          <p>
            A veces atravesar una crisis es un buen motivo para empezar algo
            positivo. Es así como la historia de Arte On U comenzó de la mano de
            su fundador y Director Artístico, Miguel Pérez Lem. En 2020....
          </p>
          <div
            className="ButtonContent ButtonLightMini"
            onClick={() => navigate("/about")}
          >
            SEE MORE
            <i className="icon-ArrowRight"></i>
          </div>
        </div>
        <div className="ArtFansBox">
          <div className="ArtFans"></div>
          <div className="StrokeDash"></div>
          <div>
            <ul>
              <li>10% off your first online order.</li>
              <li>Accumulate points and earn benefits</li>
              <li>Shopping assistance with the designer</li>
              <li>And more</li>
            </ul>
          </div>
          <div
            className="ButtonContent ButtonLightMini"
            onClick={() => navigate("/login")}
          >
            Sign Up
            <i className="icon-ArrowRight"></i>
          </div>
        </div>
      </article>
      
    </section>
  );
};

export default Component;
