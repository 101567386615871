import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ItemDireccion from "../../components/ItemDireccion";
import { TokenContext } from "../../context/TokenContext";
import axios from "../../fetch/axios";
import { useSnackbar } from "notistack";
import { CartContext } from "../../context/CardContext";

const DireccionEnvio = () => {
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const { getToken } = useContext(TokenContext);
  const { getCardId } = useContext(CartContext);
  const [direcciones, setDirecciones] = useState([]);
  const [ocultar, setOcultar] = useState(true)
  const [selectedDireccionId, setSelectedDireccionId] = useState(null);

  const [street, setStreet] = useState(null);
  const [cp, setCp] = useState(null);
  const [city, setCity] = useState(null);
  const [country, setCountry] = useState(142);
  const [detail, setDetail] = useState(null);

  const handleSelect = (direccionId) => {
    setSelectedDireccionId(direccionId);
    setOcultar(true)
  };

  const handleEditar = (dir) => {
    setSelectedDireccionId(dir.id);
    setStreet(dir.line)
    setCp(dir.postal_code)
    setDetail(dir.details)
    setCity(dir.city)
    setOcultar(false)
  };

  const handlerOcultar = () => {
    if (ocultar) {
      setSelectedDireccionId(false)
      setStreet('')
      setCp('')
      setDetail('')
      setCity('')
    }
    setOcultar(!ocultar)
  }

  const guardar = async (type = 1) => {

    if (!street) {
      enqueueSnackbar("El campo de dirección es obligatorio", { variant: "error" });
      return;
    }
    if (!cp || isNaN(cp)) {
      enqueueSnackbar("El código postal debe ser un número entero", { variant: "error" });
      return;
    }
    if (!city) {
      enqueueSnackbar("El campo de ciudad es obligatorio", { variant: "error" });
      return;
    }
    try {
      const dir= await axios[type == 1 ? 'post' : 'put'](
        type == 1 ? '/addresses/create' : '/addresses/update',
        {
          id: selectedDireccionId ? selectedDireccionId : null,
          country_id: country,
          line: street,
          postal_code: parseInt(cp),
          city: city,
          details: detail
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getToken()}`
          }
        }
      );
      enqueueSnackbar("Dirección guardada con éxito", { variant: "success" });
      setOcultar(true)
      setSelectedDireccionId(dir.data.id)
      setStreet('')
      setCp('')
      setDetail('')
      setCity('')

      fetchDirecciones()
    } catch (error) {
      console.error('Error al guardar la dirección:', error);
    }


  };

  const fetchDirecciones = async () => {
    try {

      const storedToken = getToken();

      const response = await axios.get('/addresses/list',
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${storedToken}`
          },
        }
      );
      setDirecciones(response.data);
    } catch (error) {
      console.error('Error fetching address list', error);
    }
  };

  const continuar = async()=>{

    if(!selectedDireccionId){
      enqueueSnackbar("Debe seleccionar una dirección", { variant: "error" });
      return
    }else{

      try {

        const storedToken = getToken();
  
        const response = await axios.put('/cart/address',
          {
            
              "cart_id": getCardId(),
              "address_id": selectedDireccionId
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${storedToken}`
            },
          }
        );
        navigate("/checkout-resumen")
      } catch (error) {
        console.error('Error fetching address list', error);
      }  
    }
  }

  useEffect(() => {


    fetchDirecciones()

  }, [])

  return (
    <section className="CheckoutBox">
      <article>
        <div className="StepCheckout">
          <div className="Selected"></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="icon-HouseLine" onClick={()=>navigate('/home')}></div>
        <h5>Dirección de envío</h5>
        <div className="DireccionesGuardadas">
          <div>Direcciones guardadas</div>
          <div className="FontSmall">
            Selecciona la dirección a donde enviaremos tu compra.
          </div>
          {direcciones.length > 0 &&

            direcciones.map((direccion, index) => (
              <ItemDireccion key={index} direccion={direccion} onSelect={handleSelect} handleEditar={handleEditar} />
            ))}

          <div className="text-description">El cobro es <em>standard shipping</em>, si requieres otra forma de envío, por favor contáctanos.</div>

          {ocultar &&
            <div className="ButtonLightMedium" onClick={() => handlerOcultar()}>
              <i>+</i>
              AGREGAR OTRA DIRECCIÓN
            </div>}
        </div>

        

        {!ocultar &&

          <div className="FormDireccion">
            <div>Agregar dirección de envío</div>
            <div className="FontSmall">
              Indica la dirección a donde enviaremos tu compra.
            </div>
            <label className="FormData">
              <em className="RequiredData">Calle, Número, Apartamento</em>
              <div className="FormInputLight">
                <input placeholder="Calle, Número, Apartamento" onChange={(e) => { setStreet(e.target.value) }} value={street}/>
              </div>
            </label>
            <div className="Row">
              <label className="FormData">
                <em className="RequiredData">Código postal</em>
                <div className="FormInputLight">
                  <input placeholder="Código postal" onChange={(e) => { setCp(e.target.value) }}  value={cp} />
                </div>
              </label>
              <label className="FormData">
                <em className="RequiredData">Ciudad</em>
                <div className="FormInputLight">
                  <input placeholder="Ciudad" onChange={(e) => { setCity(e.target.value) }} value={city}  />
                </div>
              </label>
            </div>
            <label className="FormData">
              <em className="RequiredData">Pais</em>
              <div className="FormInputLight">
                <select placeholder="Email" onChange={(e) => { setCountry(e.target.value) }} value={country}>
                  <option value="124" selected>
                    México
                  </option>
                  <option value="233">USA</option>
                </select>
                <i className="InputIcon icon-Select"></i>
              </div>
            </label>
            <label className="FormData">
              <em>Detalles</em>
              <div className="FormInputLight">
                <textarea placeholder="Agrega detalles de la dirección" onChange={(e) => { setDetail(e.target.value) }} value={detail}></textarea>
              </div>
            </label>
            <button className="ButtonLightMedium" onClick={() => { guardar(selectedDireccionId ? 2 : 1) }}>
              {selectedDireccionId ? 'ACTUALIZAR DATOS' : 'GUARDAR DATOS'}
            </button>
          </div>

        }
        <div
          className="ButtonDarkMedium"
          onClick={() => continuar()}
        >
          CONTINUAR
          <i className="icon-Check"></i>
        </div>
      </article>
    </section>
  );
};
export default DireccionEnvio;
