import React from "react";
import { useNavigate } from "react-router-dom";
import { Person7, Person12 } from "../../components/Images";
import InstagramBox from "../../components/InstagramBox";
const Component = () => {
  const navigate = useNavigate();
  return (
    <>
      <section className="SobreNosotros ImageButton">
        <article
          className="ImageButtonBG"
          style={{ backgroundImage: `url(${Person12})` }}
        >
          <div>
            <div className="ButtonContent ButtonDark">About Us</div>
          </div>
        </article>
        <article className="MainBox">
          <div>
            <div className="Row">
              <div>
                <div className="ArtOnU BlackImage"></div>
                <p>
                  Dorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                  eu turpis molestie, dictum est a, mattis tellus. Sed
                  dignissim, metus nec fringilla accumsan, risus sem
                  sollicitudin lacus, ut interdum tellus elit sed risus.
                  Maecenas eget condimentum velit, sit amet feugiat lectus.
                  Class aptent taciti sociosqu ad litora torquent per conubia
                  nostra, per inceptos himenaeos. Praesent auctor purus luctus
                  enim egestas, ac scelerisque ante pulvinar. Donec ut rhoncus
                  ex. Suspendisse ac rhoncus nisl, eu tempor urna. Curabitur vel
                  bibendum lorem. Morbi convallis convallis diam sit amet
                  lacinia. Aliquam in elementum tellus.
                </p>
                <p>
                  Dorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                  eu turpis molestie, dictum est a, mattis tellus. Sed
                  dignissim, metus nec fringilla accumsan, risus sem
                  sollicitudin lacus, ut interdum tellus elit sed risus.
                  Maecenas eget condimentum velit, sit amet feugiat lectus.
                  Class aptent taciti sociosqu ad litora torquent per conubia
                  nostra, per inceptos himenaeos. Praesent auctor purus luctus
                  enim egestas, ac scelerisque ante pulvinar. Donec ut rhoncus
                  ex. Suspendisse ac rhoncus nisl, eu tempor urna. Curabitur vel
                  bibendum lorem. Morbi convallis convallis diam sit amet
                  lacinia. Aliquam in elementum tellus.
                </p>
              </div>
              <div>
                <div
                  className="AboutImage"
                  style={{ backgroundImage: `url(${Person7})` }}
                ></div>
                <div className="WeCreateToBeYourself BlackImage"></div>
                <div className="ButtonContent ButtonLightMedium">
                  VER CATEGORÍAS
                  <i className="icon-ArrowRight"></i>
                </div>
              </div>
            </div>
            <div className="InstagramAbout Row">
              {<InstagramBox mini />}
              <div>
                <p>
                  Dorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                  eu turpis molestie, dictum est a, mattis tellus. Sed
                  dignissim, metus nec fringilla accumsan, risus sem
                  sollicitudin lacus, ut interdum tellus elit sed risus.
                  Maecenas eget condimentum velit, sit amet feugiat lectus.
                  Class aptent taciti sociosqu ad litora torquent per conubia
                  nostra, per inceptos himenaeos. Praesent auctor purus luctus
                  enim egestas, ac scelerisque ante pulvinar. Donec ut rhoncus
                  ex. Suspendisse ac rhoncus nisl, eu tempor urna. Curabitur vel
                  bibendum lorem. Morbi convallis convallis diam sit amet
                  lacinia. Aliquam in elementum tellus.
                </p>
                <p>
                  Nam metus lacus, porttitor eu mauris a, blandit ultrices nibh.
                  Mauris sit amet magna non ligula vestibulum eleifend. Nulla
                  varius volutpat turpis sed lacinia. Nam eget mi in purus
                  lobortis eleifend. Sed nec ante dictum sem condimentum
                  ullamcorper quis venenatis nisi. Proin vitae facilisis nisi,
                  ac posuere leo.
                </p>
              </div>
            </div>
            <div>
              Nam metus lacus, porttitor eu mauris a, blandit ultrices nibh.
              Mauris sit amet magna non ligula vestibulum eleifend. Nulla varius
              volutpat turpis sed lacinia. Nam eget mi in purus lobortis
              eleifend. Sed nec ante dictum sem condimentum ullamcorper quis
              venenatis nisi. Proin vitae facilisis nisi, ac posuere leo.
            </div>
          </div>
        </article>
        <article>
          <div className="Contactanos">
            <div>
              <b>¿Tienes dudas?</b>
            </div>
            <div>¡Ponte en contacto con nosotros!</div>
            <div
              className="ButtonContent ButtonDark"
              onClick={() => navigate("/contacto")}
            >
              CONTACT US
            </div>
          </div>
        </article>
      </section>
    </>
  );
};

export default Component;
