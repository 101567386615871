import React from 'react';
import { useNavigate } from 'react-router-dom';


const CambiadoPassword = () => {

  const navigate = useNavigate();
	
	return (
		<>
			<div className='icon-Check IconMedium'></div>
			<div>
				<h5>Se ha cambiado<br />la contraseña con éxito</h5>
			</div>
			<div className='TextLeft'>
				Puedes iniciar sesión con tu nueva contraseña
			</div>
			<div className='ButtonLightMedium' onClick={()=>{navigate('/login')}}>
				INICIAR SESIÓN
				<i className='icon-ArrowRight'></i>
			</div>
		</>
	);
};

export default CambiadoPassword;