import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from '../../fetch/axios';
import { useSnackbar } from "notistack";

const RestablecerPassword = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [emailValue, setEmail] = useState("");

  const EnviarReset = async e => {

    if(emailValue){
      try {
        const res = await axios.post(
          "/users/password-recovery",
          {
            email: emailValue,
          }
        );
        if (res.data.errors) {;
          enqueueSnackbar(res.data.message, { variant: "error" });
        } else {
          localStorage.setItem('token_recuperar_clave', res.data.token);
          localStorage.setItem('token_email', emailValue);
          navigate("/login-confirmar-recuperar")
        }
      } catch (error) {
        enqueueSnackbar("Error al enviar el correo de recuperación de clave", { variant: "error" });
      }
    }else{
      enqueueSnackbar("Debe introducir un correo", { variant: "error" });
    }
  };

  return (
    <>
      <div>
        Ingresa el correo electrónico que utilizaste al registrarte para
        recuperar tu contraseña. Recibirás un enlace para restablecer tu
        contraseña .
      </div>
      <label className="FormData">
        <em className="RequiredData">Email</em>
        <div className="FormInput">
          <input placeholder="Email" onChange={(e) => setEmail(e.target.value)}/>
        </div>
      </label>
      <div
        className="ButtonLightMedium"
        onClick={EnviarReset}
      >
        RECUPERAR CONTRASEÑA
        <i className="icon-ArrowRight"></i>
      </div>
    </>
  );
};

export default RestablecerPassword;
