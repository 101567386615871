// import logo from "../assets/icons/ArtOnUv2.svg";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import instance from "../fetch/axios";
import { TokenContext } from "../context/TokenContext";
import { CartContext } from "../context/CardContext";
import LanguajeCurrency from "./LanguajeCurrency";

const Component = ({ wishlist,cart }) => {

  const { getToken } = useContext(TokenContext);

  const [searchValue, setSearchValue] = useState("");
  const navigate = useNavigate();
  const { totalQuantity } = useContext(CartContext);

  const handleSearch = () => {
    if (searchValue) {
      instance.get(`/products/search?search=${searchValue}&per_page=8`)
        .then(response => {
          if (response.data.products.length === 0) {
            navigate('/sin-resultados', {
              state: { searchTerm: searchValue }
            });
            return;
          }
          navigate('/resultados', { 
            state: { 
              products: response.data.products,
              pages: response.data.pages,
              searchTerm: searchValue
            }
          });
        })
        .catch(() => {
          navigate('/sin-resultados', {
            state: { searchTerm: searchValue }
          });
        });
    } else {
      navigate('/sin-resultados');
    }
  };

  return (
    <header className="Column">
      {/*<img src={logo} alt="logo" />*/}
      <div className="HeaderFirst">
        <div
          className="ImageIcon ArtOnU"
          onClick={() => navigate("/home")}
        ></div>
        <div className="HeaderMenus">
          <label className="FormData">
            <div className="FormInputMedium">
              <input
                placeholder="Buscar un producto"
                type="search"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <i className="InputIcon icon-Search" onClick={handleSearch}></i>
            </div>
          </label>
          <div className="Bubbles Row">
            <div className="icon-User" onClick={() => navigate(getToken() ? "/mi-perfil" : "/login")}>
              {" "}
              {getToken() ? "Mi cuenta" : "Iniciar sesión"}
            </div>
            
            <div className="icon-Hearty" onClick={() => getToken() ? navigate("/wishlist") :navigate("/login")  }>
              {wishlist ? <div className="Bubble">{wishlist}</div> : ""}
            </div>
            <div
              className="icon-Trolley"
              onClick={() => navigate("/carrito")}
            >
              {(getToken() && totalQuantity>0) ? <div className="Bubble">{totalQuantity}</div> : ""}
            </div>
            {/*<div className="icon-Burguer"></div>*/}
          </div>
        </div>
      </div>

      <LanguajeCurrency />
    </header>
  );

};

export default Component;
