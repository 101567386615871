import title from "../../assets/icons/terminosycondiciones.svg";
import React from "react";
import { useNavigate } from "react-router-dom";
import { BannerLegal } from "../../components/Images";
import { BannerLegalMov } from "../../components/Images";
const Component = () => {
  const navigate = useNavigate();
  return (
    <>
      <section className="ArticleStaticLegal ImageButton">
        <article className="ImageButtonBG" >
          <picture>
            <source media="(max-width:700px)" srcSet={BannerLegalMov} type="image/webp" />           
            <img src={BannerLegal} alt="" />
          </picture>
          <div>
            <div>
              <div className="ButtonContent ButtonDark">Privacy Policy</div>
            </div>
          </div>
        </article>
        <article className="ArticleStaticContent">
          <div className="ArticleStaticContentText">
            <strong>Arte On U</strong><br />
            <strong>Privacy Policy</strong><br />
            <strong>WE RESPECT YOUR PRIVACY; THIS IS A FACT!</strong><br />
            <p>Arte On U takes your privacy seriously, and this Privacy Policy governs our use of your personal information. Our primary goal in collecting information through ArteOnU.com is to provide and improve our services, features, and content, and to enable you to use our site effectively and enjoyable. All information collected on this site is kept strictly confidential, and will not be sold, disclosed to third parties or reused without your permission.
            We collect the personal information you provide to us (including your name and your email and postal addresses) when you place an order or participate in a sweepstakes, promotion, or survey. If you participate in a contest or sweepstakes, we may in occasion share some limited personal information with brands with whom we have partnered on those promotions. We also monitor site use and traffic patterns to improve our website design and the products and services that we offer, using cookies and pixel tags. We take appropriate steps to maintain and use our customer's personal information securely and responsibly.<br />
            When you place an order on ArteOnU.com, your personal information and your credit card data is safeguarded with 128-bit Secure Sockets Layer encryption. We do not share credit card information with third parties except for the purpose of processing payment for products you have ordered as required by law.<br />
            If at any time you would like us to delete any of your personal information on file, please email us at info@arteonu.com, being sure to include your email address and full name.</p>

            <strong>Arte On U</strong><br />
            <strong>Política de privacidad</strong><br />
            <strong>RESPETAMOS TU PRIVACIDAD; ¡ESTO ES UN HECHO!</strong><br />
            <p>Arte On U se toma en serio su privacidad y esta Política de privacidad rige nuestro uso de su información personal. Nuestro objetivo principal al recopilar información a través de ArteOnU.com es proporcionar y mejorar nuestros servicios, características y contenido, y permitirle utilizar nuestro sitio de manera efectiva y agradable. Toda la información recopilada en este sitio se mantiene estrictamente confidencial y no se venderá, divulgará a terceros ni se reutilizará sin su permiso.<br />
            Recopilamos la información personal que nos proporciona (incluido su nombre y su dirección de correo electrónico y postal) cuando realiza un pedido o participa en un sorteo, promoción o encuesta. Si participa en un concurso o sorteo, en ocasiones podemos compartir información personal limitada con marcas con las que nos hemos asociado en esas promociones. También supervisamos el uso del sitio y los patrones de tráfico para mejorar el diseño de nuestro sitio web y los productos y servicios que ofrecemos, utilizando cookies y pixels tags. Tomamos las medidas adecuadas para mantener y utilizar la información personal de nuestros clientes de forma segura y responsable.<br />
            Cuando realiza un pedido en ArteOnU.com, su información personal y los datos de su tarjeta de crédito están protegidos con cifrado Secure Sockets Layer de 128 bits. No compartimos información de tarjetas de crédito con terceros, excepto con el fin de procesar el pago de los productos que haya pedido según lo exige la ley.<br />
            Si en algún momento desea que eliminemos su información personal registrada, envíenos un correo electrónico a info@arteonu.com, asegurándose de incluir su dirección de correo electrónico y nombre completo.</p>

            
            <br /><br />
          </div>
          <div className="Contactanos">
            <div>
              <b>¿Tienes dudas?</b>
            </div>
            <div>¡Ponte en contacto con nosotros!</div>
            <div
              className="ButtonContent ButtonDark"
              onClick={() => navigate("/contacto")}
            >
              CONTACT US
            </div>
          </div>
        </article>
      </section>
    </>
  );
};

export default Component;
