import title from "../../assets/icons/terminosycondiciones.svg";
import React from "react";
import { useNavigate } from "react-router-dom";
import { BannerLegal } from "../../components/Images";
import { BannerLegalMov } from "../../components/Images";
const Component = () => {
  const navigate = useNavigate();
  return (
    <>
      <section className="ArticleStaticLegal ImageButton">
        <article className="ImageButtonBG" >
          <picture>
            <source media="(max-width:700px)" srcSet={BannerLegalMov} type="image/webp" />           
            <img src={BannerLegal} alt="" />
          </picture>
          <div>
            <div>
              <div className="ButtonContent ButtonDark">Returns and exchanges</div>
            </div>
          </div>
        </article>
        <article className="ArticleStaticContent">
          <div className="ArticleStaticContentText">
            <strong>RETURNS AND EXCHANGES </strong><br /><br />

            <p>I want to return or exchange all or part of my order. What do I do?<br />
            We only accept returns and exchanges from Mexico and the United States.</p>

            <p>Until further notice we no longer accept returns/exchanges from other countries due to customs-related issues. But please, contact our support team to discuss for this matter. </p>

            <p>We accept returns and exchanges of unused items in their original condition within 30 days of delivery. <br />
            Also as long has not been damaged, soiled, washed or altered.  This excludes bathing suits or intimate wear, which cannot be returned or exchanged, as well as On Sale and discounted items.  <br />
            Custom made items cannot be returned or exchanged. <br />
            Jewelry and Accessories items cannot be returned or exchanged. <br />
            After the returned piece or pieces has been received at our facilities, you will receive an email confirming our acceptance (if everything it's 100% OK under our previous return/exchange policies) and we will proceed to give you the alternatives that we have for you at the moment (exchange and return) <br />
            To initiate an exchange or return, <br />
            For Mexico: please click here (crear link) and submit your order number and email address.<br />
            For USA: please contact us and submit your order number and email address.</p>

            <p>Customer Service <br />
            The best and fastest way to contact us is at (arteonu email)</p>

            <strong>DEVOLUCIONES Y CAMBIOS </strong><br /><br />

            <p>Quiero devolver o cambiar todo o parte de mi pedido. ¿Qué hago?<br />
            Solo aceptamos devoluciones y cambios desde México y Estados Unidos.</p>

            <p>Hasta nuevo aviso, no aceptamos devoluciones/cambios desde otros países debido a problemas relacionados con aduanas. Pero por favor, contacta a nuestro equipo de soporte para hablar sobre este asunto. </p>

            <p>Aceptamos devoluciones y cambios de artículos sin usar, en su estado original dentro de los 30 días posteriores a la entrega. <br />
            También siempre que no se hayan dañado, ensuciado, lavado o alterado. <br />
            Esto excluye trajes de baño o ropa íntima, que no se pueden devolver ni cambiar, así como artículos en oferta y con descuento. <br />
            Los artículos hechos a medida no se pueden devolver ni cambiar. <br />
            Los artículos de joyería y accesorios no se pueden devolver ni cambiar. <br />
            Una vez recibida la pieza o piezas devueltas en nuestras instalaciones, recibirás un correo electrónico confirmando nuestra aceptación (si todo está 100% OK bajo nuestras políticas de devolución/cambio anteriores) y procederemos a darte las alternativas que tenemos para ti en este momento (cambio y devolución) <br />
            Para iniciar un cambio o devolución, <br />
            Para México: haz clic aquí (crear link) y envía tu número de pedido y dirección de correo electrónico. <br />
            Para Estados Unidos: contáctanos y envía tu número de pedido y dirección de correo electrónico. </p>

            <p>Atención al cliente<br />
            La mejor y más rápida forma de contactarnos es en (arteonu email)</p>


            
            <br /><br />
          </div>
          <div className="Contactanos">
            <div>
              <b>¿Tienes dudas?</b>
            </div>
            <div>¡Ponte en contacto con nosotros!</div>
            <div
              className="ButtonContent ButtonDark"
              onClick={() => navigate("/contacto")}
            >
              CONTACT US
            </div>
          </div>
        </article>
      </section>
    </>
  );
};

export default Component;
