import React, { useContext, useState } from "react";
import { Person4 } from "../../components/Images";
import CheckCircle from "../../assets/icons/CheckCircle.svg";
import { useSnackbar } from "notistack";
import axios from "../../fetch/axios"
import { TokenContext } from "../../context/TokenContext";


const Component = () => {
  const [showModal, setShowModal] = useState(false);
  const [name,setNombre] = useState(null)
  const [email,setEmail] = useState(null)
  const [message,setMessage] = useState(null)
  const { enqueueSnackbar } = useSnackbar();
  const { getToken } = useContext(TokenContext);

  const handleSubmit = async() => {

    if(!name || !email || !message){
      enqueueSnackbar("Faltan campos por llenar", { variant: "error" });
      return;
    }

    try {
      const res = await axios.post(
        "/contact/send",
        {
          "name": name,
          "email": email,
          "message": message
        },
        {
          headers: { 'Authorization': 'Bearer ' + getToken() }
        }
      );
      if (res.data.errors) {
        enqueueSnackbar(res.data.message, { variant: "error" });
      } else {

        setEmail('')
        setNombre('')
        setMessage('')
        setShowModal(true); 
      }
    } catch (error) {
      enqueueSnackbar(error.response.data.message, { variant: "error" });
    }
  };

  const handleCloseModal = () => {
    setShowModal(false); 
  };



  return (
    <>
      <section className="Contacto ImageButton">
        <article
          className="ImageButtonBG"
          style={{ backgroundImage: `url(${Person4})` }}
        >
          <div>
            <div className="ButtonContent ButtonDark">CONTACTO</div>
          </div>
        </article>
        <article className="ContactoBox">
          <div>
            <h5>Envíanos un mensaje</h5>
            <label className="FormData">
              <em className="RequiredData">Nombre</em>
              <div className="FormInputLight">
                <input placeholder="Nombre" onChange={(e)=>{setNombre(e.target.value)}} value={name}/>
              </div>
            </label>
            <label className="FormData">
              <em className="RequiredData">Email</em>
              <div className="FormInputLight">
                <input placeholder="Email" onChange={(e)=>{setEmail(e.target.value)}} value={email} />
              </div>
            </label>
            <label className="FormData">
              <em className="RequiredData">Mensaje</em>
              <div className="FormInputLight">
                <textarea placeholder="Escribe un mensaje" onChange={(e)=>{setMessage(e.target.value)}} value={message}> </textarea>
              </div>
            </label>
            <div
              className="ButtonContent ButtonDarkMedium"
              onClick={()=>{handleSubmit()}}
            >
              ENVIAR
            </div>
          </div>
          <div className="TextCenter">
            <b className="FontNormal">¡Síguenos!</b>
            <div>
              <i className="icon-Facebook"></i>
              <i className="icon-Instagram"></i>
              <i className="icon-Tiktok"></i>
              <i className="icon-X"></i>
            </div>
          </div>
        </article>
      </section>

      {/* Modal de agradecimiento */}
      {showModal && (
        <div className="ModalGracias">
          <div className="ModalContent">
            <i className="icon-Closed" onClick={handleCloseModal}></i>
            <div className="icon-Check"><img src={CheckCircle} alt="" /></div>
            <h4>Formulario enviado con éxito</h4>
            <p>Gracias por contactarnos, nos comunicaremos contigo.</p>
            <div className="ButtonDarkMedium" onClick={handleCloseModal}>ACEPTAR</div>
          </div>
        </div>
      )}

    </>
  );
};

export default Component;