import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import close from "../../assets/icons/close-sesion.svg";
import Breadcrumb from "../../components/Breadcrumb";
import Direccion from "../../components/Direccion";
import axios from "../../fetch/axios";
import { TokenContext } from "../../context/TokenContext";

const Component = () => {

  const navigate = useNavigate();
  const [direcciones, setDirecciones] = useState([]);
  const [user,setUser] =useState(null);
  
  const { getToken } = useContext(TokenContext);

  useEffect(() => {
    
    const fetchDirecciones = async () => {
      try {

        const storedToken = getToken();

        const response = await axios.get('/addresses/list',
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${storedToken}`
            },
          }
        );
        setDirecciones(response.data);
      } catch (error) {
        console.error('Error fetching address list', error);
      }
    };

    const fetchUsuario = async () => {
      try {

        const storedToken = getToken();

        const response = await axios.get('/users/data',
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${storedToken}`
            },
          }
        );
        setUser(response.data);
        localStorage.setItem('user_arteonu',JSON.stringify(response.data))
      } catch (error) {
        console.error('Error fetching user get', error);
      }
    };


    fetchDirecciones();
    fetchUsuario()
  }, []);

  const cerrarSesion = ()=>{
    localStorage.clear();
    navigate('/login')
  }

  return (
    <>
      <div className="PerfilContent">
        <Breadcrumb title="MiPerfil" perfil />

        <div className="grid-title">
          <div className="ArtFans BlackIconPerfil ArtFansPerfil"></div>
          <span></span>
          <h2>Mi Perfil</h2>
        </div>

        <hr />
        <section className="PerfilData">

          {/* EDITAR MIS DATOS */}
          <article>
            <div className="grid">
              <div className="icon-IdentificationCard"></div>
              <b>Mis Datos</b>
            </div>
            <div className="ItemData">
              <div className="Selected">
                <div><strong>Nombre:</strong> {user ? user.name : 'cardango...'}</div>
              </div>
            </div>
            <div className="ItemData">
              <div className="Selected">
                <div><strong>Télefono:</strong> {user ? '+' +user.code_phone : 'cardango...'} {user ? user.phone : ''}</div>
              </div>
            </div>
            <div className="ItemData">
              <div className="Selected">
                <div><strong>Email:</strong> {user ? user.email : 'cardango...'}</div>
              </div>
            </div>
            <div className="ItemData">
              <div className="Selected">
                <div><strong>No. Afiliación:</strong> {user ? user.affiliation : 'cardango...'} </div>
              </div>
            </div>
            <div className="ItemData">
              <div className="Selected">
                <div><a href="javascript:void(0)" className="link" onClick={() => navigate("/mi-perfil/editar-datos")}>Editar datos</a></div>
              </div>
            </div>
            <div className="ItemData" onClick={()=>{cerrarSesion()}}>
              <div className="Selected">
                <div><a href="javascript:void(0)" className="link"> <img src={close} alt="" /> Cerrar sesión</a></div>
              </div>
            </div>
          </article>

          {/* MIS PEDIDOS */}
          <article>
            <div className="grid">
              <div className="icon-Package"></div>
              <b>Pedidos <em>Rastrear paquetes, devolver pedidos o comprar algo de nuevo.</em></b>
            </div>


            <div className="ButtonLight ButtonContent SeePackages" onClick={() => navigate("/mis-pedidos")}>
              VER MIS PEDIDOS
            </div>
          </article>

          {/* DIRECCIONES */}
          <article>
            <div className="grid">
              <div className="icon-HouseLine"></div>
              <b>Direcciones guardadas <em>Direcciones guardadas para entrega de tus compras.</em></b>
            </div>

            {direcciones &&
              direcciones.map((direccion, index) => (
                <Direccion
                cion key={direccion.id || index} stored address={direccion} />
              ))
            }

            {direcciones.length<3 && 
            <div className="ButtonLight ButtonContent" onClick={() => { navigate('/mi-perfil/agregar-direccion') }}>
              <i>+</i>
              AGREGAR OTRA DIRECCIÓN
            </div>}
          </article>

        </section>
      </div>
    </>
  );
};

export default Component;
