import React from "react";
import { useNavigate } from "react-router-dom";
import { Person4 } from "../../components/Images";
const Component = () => {
  const navigate = useNavigate();
  return (
    <>
      <section className="ArticleStatic ImageButton">
        <article
          className="ImageButtonBG"
          style={{ backgroundImage: `url(${Person4})` }}
        >
          <div>
            <div className="ButtonContent ButtonDark">TABLA DE TALLAS</div>
          </div>
        </article>
        <article className="ArticleStaticContent">
          <div>
            <b>II Etiam eu turpis</b>
            <p>
              Dorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu
              turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus
              nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum
              tellus elit sed risus. Maecenas eget condimentum velit, sit amet
              feugiat lectus. Class aptent taciti sociosqu ad litora torquent
              per conubia nostra, per inceptos himenaeos. Praesent auctor purus
              luctus enim egestas, ac scelerisque ante pulvinar. Donec ut
              rhoncus ex. Suspendisse ac rhoncus nisl, eu tempor urna. Curabitur
              vel bibendum lorem. Morbi convallis convallis diam sit amet
              lacinia. Aliquam in elementum tellus.
            </p>
            <b>III Etiam eu turpis</b>
            <p>
              Dorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu
              turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus
              nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum
              tellus elit sed risus. Maecenas eget condimentum velit, sit amet
              feugiat lectus. Class aptent taciti sociosqu ad litora torquent
              per conubia nostra, per inceptos himenaeos. Praesent auctor purus
              luctus enim egestas, ac scelerisque ante pulvinar. Donec ut
              rhoncus ex. Suspendisse ac rhoncus nisl, eu tempor urna. Curabitur
              vel bibendum lorem. Morbi convallis convallis diam sit amet
              lacinia. Aliquam in elementum tellus.
            </p>
            <b>IV Etiam eu turpis</b>
            <p>
              Dorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu
              turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus
              nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum
              tellus elit sed risus. Maecenas eget condimentum velit, sit amet
              feugiat lectus. Class aptent taciti sociosqu ad litora torquent
              per conubia nostra, per inceptos himenaeos. Praesent auctor purus
              luctus enim egestas, ac scelerisque ante pulvinar. Donec ut
              rhoncus ex. Suspendisse ac rhoncus nisl, eu tempor urna. Curabitur
              vel bibendum lorem. Morbi convallis convallis diam sit amet
              lacinia. Aliquam in elementum tellus.
            </p>
            <p>
              Nam metus lacus, porttitor eu mauris a, blandit ultrices nibh.
              Mauris sit amet magna non ligula vestibulum eleifend. Nulla varius
              volutpat turpis sed lacinia. Nam eget mi in purus lobortis
              eleifend. Sed nec ante dictum sem condimentum ullamcorper quis
              venenatis nisi. Proin vitae facilisis nisi, ac posuere leo.
            </p>
          </div>
          <div className="Contactanos">
            <div>
              <b>¿Tienes dudas?</b>
            </div>
            <div>¡Ponte en contacto con nosotros!</div>
            <div
              className="ButtonContent ButtonDark"
              onClick={() => navigate("/contacto")}
            >
              CONTACT US
            </div>
          </div>
        </article>
      </section>
    </>
  );
};

export default Component;
