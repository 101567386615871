import React, { useState, useEffect, useContext } from "react";
import axios from '../fetch/axios';
import { LanguageCurrencyContext } from "../context/LanguageCurrencyContext";
// import { Person2, Person9, Person12 } from ".//Images";

const Component = ({ mini }) => {
  const [igImages, setIgImages] = useState([]);
  const [loadingIgImages, setLoadingIgImages] = useState(true);
  const { lang } = useContext(LanguageCurrencyContext);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get('/posts/home' + `?lang=`+lang);
        setIgImages(response.data);
      } catch (error) {
        console.error('Error fetching Instagram posts', error);
      } finally {
        setLoadingIgImages(false);
      }
    };
    fetchPosts();
  }, [lang]);

  const RotateIG = (event, setindex) => {
    setindex = setindex === undefined ? false : setindex;
    const InstagramBox = event.currentTarget.parentNode;
    if (window.getComputedStyle(InstagramBox).display === "block") {
      let childs = InstagramBox.children;
      childs = Array.from(childs);
      const clickedIndex =
        setindex !== undefined ? setindex : childs.indexOf(event.currentTarget);
      if (clickedIndex === 2) {
        const firstChild = childs[0];
        InstagramBox.appendChild(firstChild);
      }
      if (clickedIndex === 0) {
        const lastChild = childs[childs.length - 1];
        InstagramBox.insertBefore(lastChild, childs[0]);
      }
    }
  };

  let startX = 0;
  let endX = 0;

  const handleMouseDown = (e) => {
    startX = e.clientX;
  };

  const handleMouseUp = (e) => {
    endX = e.clientX;
    const difference = endX - startX;
    if (difference > 0) {
      RotateIG(e, 0);
    } else {
      RotateIG(e, 2);
    }
  };

  const handleTouchStart = (e) => {
    startX = e.touches[0].clientX;
  };

  const handleTouchEnd = (e) => {
    endX = e.changedTouches[0].clientX;
    const difference = endX - startX;
    if (difference > 0) {
      RotateIG(e, 0);
    } else {
      RotateIG(e, 2);
    }
  };

  function InstagramItem(image, text,link,index) {
    return (
      <div
        key={index}
        className="InstagramItem"
        onClick={RotateIG}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
      >
        <a href={link}>
          <div style={{ backgroundImage: `url(${image})` }}></div>
          <p className="TextLeft">
            <i className="icon-Instagram"></i>
            <span>{text}</span>
          </p>
          </a>
      </div>
    );
  }

  const LoadingCard = () => {
    return (
      <>
          <div className="col-md-3">
            <div className="wrapper">
              <div className="card-loader card-loader--tabs"></div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="wrapper">
              <div className="card-loader card-loader--tabs"></div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="wrapper">
              <div className="card-loader card-loader--tabs"></div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="wrapper">
              <div className="card-loader card-loader--tabs"></div>
            </div>
          </div>
      </>
    );
  };

  return (
    <div className={`InstagramBox${mini ? " IGBoxMini" : ""}`}>
      {loadingIgImages ? <div className="MainContentItems MCtemsButtons">{LoadingCard() }</div> : igImages.map((post,index) => InstagramItem(post.image, post.description,post.link,index))}
    </div>
  );
};

export default Component;
