import React, { useContext, useEffect } from 'react';
import { LanguageCurrencyContext } from './../context/LanguageCurrencyContext';

function LanguajeCurrency() {
  const { lang, currency, cambiarLang, cambiarCurrency } = useContext(LanguageCurrencyContext);

  useEffect(() => {
  }, [lang, currency]); // Re-render when lang or currency changes

  return (
    <div className="HeaderSelect">
      <label>
        <select name="lang" value={lang} onChange={(event) => cambiarLang(event.target.value)}>
          <option value="es">Español</option>
          <option value="en">English</option>
        </select>
        <i className="icon-ChevronDown"></i>
      </label>
      <label>
        <select name="currency" value={currency} onChange={(event) => cambiarCurrency(event.target.value)}>
          <option value="mxn">$ MXN</option>
          <option value="usd">$ USD</option>
        </select>
        <i className="icon-ChevronDown"></i>
      </label>
    </div>
  );
}

export default LanguajeCurrency;