import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  //timeout: 1000,
  // headers: {'Authorization': 'Bearer ' + localStorage.getItem('token_arteonu')}
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401 && error.response.data.message === "Unauthenticated.") {
      
      console.log('Unauthenticated');

      localStorage.removeItem('token_arteonu'); 
      localStorage.removeItem('user_arteonu'); 
      localStorage.removeItem('cart'); 
      localStorage.removeItem('token_recuperar_clave'); 
      localStorage.removeItem('token_volver_crear'); 

      window.location.href='/login'
      
    }
    return Promise.reject(error);
  }
);

export default instance;