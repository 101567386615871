import React, { useContext, useEffect, useState } from 'react';
import PodriaInteresarte from '../home/PodriaInteresarte';
import axios from "../../fetch/axios";
import { useSnackbar } from 'notistack';
import ProductoResultado from '../home/ProductoResultado';
import { TokenContext } from '../../context/TokenContext';
import { LanguageCurrencyContext } from '../../context/LanguageCurrencyContext';

const WishList = () => {

	const [productos, setProductos] = useState([]);
	const { enqueueSnackbar } = useSnackbar();
  	const { getToken } = useContext(TokenContext);
	const { lang,currency } = useContext(LanguageCurrencyContext);

	const fetchWish = async () => {

		try {
			const res = await axios.get('/products/wishlist' + `?lang=`+lang+ `&currency=`+currency, {
				headers: { 'Authorization': 'Bearer ' + getToken() }
			});
			const pd = res.data;
			setProductos(pd);

		} catch (error) {
			enqueueSnackbar("Lista de favoritos no disponible", { variant: "error" });
		}
	};

	useEffect(() => {

		fetchWish();

	}, [lang,currency])

	return (
		<>
			<div className='WishList'>
				<h3>Wishlist</h3>
				<p>{productos.length} articulos</p>
			</div>
			<section className='ColeccionItems ColeccionVertical WishList'>
				{productos.map((producto, index) => (

					<ProductoResultado
						key={index}
						producto={producto}
						ejecutar={fetchWish}
					/>

				))}
			</section>
			<PodriaInteresarte />
		</>
	);
};

export default WishList;