import { useSnackbar } from "notistack";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { CartContext } from "../../context/CardContext";
import { TokenContext } from "../../context/TokenContext";

const Component = ({ ped }) => {

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { addToCart } = useContext(CartContext);
  const { getToken } = useContext(TokenContext);


  return (
    <div className="AgregadoItem">
      <div>
        <div
          className="AgregadoItemImg"
          style={{ backgroundImage: `url(${ped.desktop_image})` }}
        ></div>
        <div>
          <p>{ped.name}</p>
          <em>Cantidad: {ped.quantity} </em>
          <em> Talla: {ped.size} </em>
          {/* <em>
              <b>Fecha de la compra: </b>
              <span>02 Noviembre 2023</span>
            </em> */}

          <em>{ped.description}</em>
          <div className="links">
            <a onClick={() => navigate("/detalles-producto/" + ped.uri)}
              style={{ cursor: "pointer" }}
            >Comprar nuevamente</a>
            <a onClick={() => { navigate("/detalles-producto/" + ped.uri) }} style={{ cursor: "pointer" }}>Ver articulo</a>
          </div>
        </div>
      </div>
      <div>
        ${ped.subtotal}

      </div>
    </div>
  );
};

export default Component;
