import React, { useContext, useEffect, useRef, useState } from "react";
import axios from '../../fetch/axios';
import ProductoResultado from "./ProductoResultado";
import PaginadoComponent from "./PaginadoComponent";
import LoadingCard from "./LoadingCard";
import { LanguageCurrencyContext } from "../../context/LanguageCurrencyContext";

const ListadoColecciones = () => {

  const coleccionPosition = useRef(null);
  const [loading, setLoading] = useState(true);

  const [productos, setProductos] = useState([]);
  const [colecciones, setColecciones] = useState([]);
  const [posicion, setPosicion] = useState(null);
  const { lang,currency } = useContext(LanguageCurrencyContext);

  const fetchColecciones = async () => {
    try {
      const responseca = await axios.get(`/collections/list` + '?lang='+lang);
      setColecciones(responseca.data);
    } catch (error) {
      console.error('Error fetching category details', error);
    }
  };

  const fetchProductos = async (coleccion) => {
    let url = `/products/search` + `?lang=`+lang+ `&currency=`+currency;

    if (coleccion !== null && coleccion !== undefined) {
      url += `?collection_id=${encodeURIComponent(coleccion)}` + `&lang=`+lang+ `&currency=`+currency;
    }

    try {
      const response = await axios.get(url);
      // Procesa la respuesta aquí
      setProductos(response.data.products);
      setLoading(false)

    } catch (error) {
      console.error('Error al obtener los productos:', error);
      throw error;
    }
  };


  // const updateRoute = (coll) => {
  //   const newUrl = `/category/${categoria}/${subcategoria}/${coll}`;
  //   return navigate(newUrl);
  // };


  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        fetchColecciones(),
        fetchProductos()
      ]);
    };

    fetchData();
  }, [lang,currency])


  function SetPositionColeccion(event) {
    const pos = ["Vertical", "Horizontal"];
    pos[2] = event.target.dataset.n.split(",");
    event.target.parentNode
      .querySelector(`.icon-${pos[pos[2][0]]}`)
      .classList.add("Selected");
    event.target.parentNode
      .querySelector(`.icon-${pos[pos[2][1]]}`)
      .classList.remove("Selected");
    coleccionPosition.current.classList.toggle("ColeccionHorizontal");
    coleccionPosition.current.classList.toggle("ColeccionVertical");
  }

  function ColeccionPagination() {
    return (
      <ul>
        <li>1</li>
        <li>2</li>
        <li className="Selected">3</li>
        <li>4</li>
        <li>5</li>
      </ul>
    );
  }

  return (
    <>

      <section className="ColeccionMenu" style={{ marginTop:"15px" }}>

        {colecciones && (
          <div>
            <p>Colecciones:</p>
            <ul>
              <li className={`${!posicion ? 'Selected' : ''}`}
                onClick={() => {
                  setPosicion(false);
                  fetchProductos();
                  setLoading(true);

                }}
              >Todas</li>
              {colecciones.map((coleccion) => (
                <li
                  key={coleccion.id}
                  className={`${posicion == coleccion.id ? 'Selected' : ''}`}
                  onClick={() => {
                    setPosicion(coleccion.id);
                    fetchProductos(coleccion.id);
                    setLoading(true);

                  }}
                >
                  {coleccion.name}</li>
              ))}
            </ul>
          </div>
        )}

        <div>
          <span
            className="icon-Vertical"
            data-n="0,1"
            onClick={SetPositionColeccion}
          >
            Vertical
          </span>
          <span
            className="icon-Horizontal Selected"
            data-n="1,0"
            onClick={SetPositionColeccion}
          >
            Horizontal
          </span>
        </div>
      </section>

      <PaginadoComponent productos={productos} loading={loading} coleccionPosition={coleccionPosition} />

      {/* <section
        className="ColeccionItems ColeccionVertical"
        ref={coleccionPosition}
      >
        {!loading ? productos.map((producto) => (
          <ProductoResultado
            key={producto.id || producto._id}
            producto={producto}
          />))
          :
          <>
            {LoadingCard()}
          </>
        }
      </section>

      <section className="ColeccionPagination">
        <div className="icon-CaretLeft"></div>
        {ColeccionPagination()}
        <div className="icon-CaretRight"></div>
      </section> */}
    </>
  );

};

export default ListadoColecciones;
