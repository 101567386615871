import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from "../fetch/axios";
import { TokenContext } from './TokenContext';
import { LanguageCurrencyContext } from './LanguageCurrencyContext';

export const CartContext = createContext({
  cartItems: [],
  totalQuantity: 0,
  totalPay: 0,
  cartId: null,
  addToCart: () => { },
  removeToCart: () => { },
  addPrevent: () => { },
  removePrevent: () => { },
  getCartItems: () => { },
  getCardId: () => { },
  getDescuento: () => { },
  resetCart: () => { },
  getShipping: () => { },
  loadCartFromLocalStorage: () => { },

});

export const CartProvider = ({ children }) => {

  const [cartItems, setCartItems] = useState([]);
  const [cartId, setCartId] = useState(null);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalPay, setTotalPay] = useState(0);
  const [descuento, setDescuento] = useState(0);
  const [shipping, setShipping] = useState(0);
  const { getToken } = useContext(TokenContext);
  const { lang, currency } = useContext(LanguageCurrencyContext);

  // Función para actualizar el estado del carrito desde localStorage
  const loadCartFromLocalStorage = async () => {

    //llamamos api para consultar carrito actual de cliente
    if (getToken()) {

      try {

        const res = await axios.get('/cart/active' + `?lang=` + lang + `&currency=` + currency, {
          headers: { 'Authorization': 'Bearer ' + getToken() }
        });

        let cart = res.data

        if (cart.id) {

          // console.log(cart) //imprimir el carrito
          localStorage.setItem('cartId', cart.id);
          setCartId(cart.id)               //obtiene el id del carrito activo
          setTotalPay(cart.total)
          setDescuento(cart.discount)
          setShipping(cart.shipping)
          setCartItems(cart.products)      //obtiene los productos del carrito
          setTotalQuantity(cart.products.length)  //obtiene el total de articulos en el carrito
          saveCartToLocalStorage(cart.products)         //guardamos en el storage los productos

        } else {

          setCartId(null)
          setCartItems([]);
          setTotalQuantity(0);
          localStorage.setItem('cart', '[]');
          localStorage.setItem('cartId', '');

        }

      } catch (error) {

        localStorage.removeItem('cart');
        localStorage.removeItem('cartId');
        localStorage.removeItem('user_arteonu');
        localStorage.removeItem('token_arteonu');
        localStorage.removeItem('addPrevent');
        localStorage.removeItem('token_recuperar_clave');
        localStorage.removeItem('roken_volver_crear');

      }

    }

  };

  const addToCart = async (item) => {

    let array = cartItems;
    let sub = [];


    if (cartId) {
      //actualizamos el carrito

      let item_size = parseInt(item.size_id);
      let item_id = item.id;

      let condicional = false;

      array.forEach(element => {

        let elem_size = parseInt(element.size_id);
        let elem_id = element.id;

        if (item_size === elem_size && item_id === elem_id) {
          item.size_id = parseInt(item.size_id);
          sub.push(item)
          condicional = true
        } else {
          sub.push(element)
          condicional = false
        }

      })

      if (array.length > 0 && !condicional) {
        item.size_id = parseInt(item.size_id);
        sub.push(item)
      }

      if (array.length == 0) {
        item.size_id = parseInt(item.size_id);
        sub.push(item)
      }

      const res = await axios.put('/cart/update', {
        id: cartId,
        products: sub
      }, {
        headers: { 'Authorization': 'Bearer ' + getToken() }
      });

      setTotalPay(res.data.total)
      setDescuento(res.data.discount)
      setShipping(res.data.shipping)
      setCartItems(sub);
      setTotalQuantity(sub.length);
      saveCartToLocalStorage(sub);

    } else {
      //creamos el carrito
      const res = await axios.post('/cart/create', {
        products: [item]
      }, {
        headers: { 'Authorization': 'Bearer ' + getToken() }
      });

      localStorage.setItem('cartId', res.data.id);
      setTotalPay(res.data.total)
      setDescuento(res.data.discount)
      setShipping(res.data.shipping)
      setCartId(res.data.id)
      setCartItems([item]);
      saveCartToLocalStorage([item]);

    }

  };

  const removeToCart = async (item) => {

    let array = cartItems
    let sub = [];

    let item_size = parseInt(item.size_id);
    let item_id = item.id;

    array.forEach(element => {

      let elem_size = parseInt(element.size_id);
      let elem_id = element.id;

      if (item_size === elem_size && item_id === elem_id) {
      } else {
        element.size_id = parseInt(element.size_id);
        sub.push(element)
      }

    });

    const res = await axios.put('/cart/update', {
      id: cartId,
      products: sub
    }, {
      headers: { 'Authorization': 'Bearer ' + getToken() }
    });

    setTotalPay(res.data.total)
    setDescuento(res.data.discount)
    setCartItems(sub);
    saveCartToLocalStorage(sub);
    setTotalQuantity(sub.length);

    return true;
  };

  const addPrevent = (item) => {
    localStorage.setItem('addPrevent', JSON.stringify(item))
  }

  const removePrevent = () => {
    localStorage.removeItem('addPrevent')
  }

  const saveCartToLocalStorage = (items = null) => {
    localStorage.setItem('cart', JSON.stringify(items ? items : cartItems));
  };

  const getCartItems = () => {
    return cartItems;
  }

  const getDescuento = () => {
    return descuento;
  }
  const getShipping = () => {
    return shipping;
  }
  const getCardId = () => {
    return cartId ? cartId : localStorage.getItem('cartId');
  }

  const resetCart = () => {
    setCartId(null)
    setCartItems([]);
    setTotalQuantity(0);
    localStorage.setItem('cart', '[]');
    localStorage.removeItem('cartId');

  }

  useEffect(() => {
    loadCartFromLocalStorage();
  }, [lang, currency]);

  return (
    <CartContext.Provider value={{
      cartItems,
      totalQuantity,
      totalPay,
      descuento,
      cartId,
      setTotalPay,
      setDescuento,
      addToCart,
      removeToCart,
      addPrevent,
      removePrevent,
      getCartItems,
      getCardId,
      getDescuento,
      resetCart,
      getShipping,
      loadCartFromLocalStorage,
    }}>
      {children}
    </CartContext.Provider>
  );
};
