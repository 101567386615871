import React, { useContext } from "react";
import {useNavigate} from "react-router-dom";
import {useGoogleLogin} from "@react-oauth/google";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import axios from "axios";
import instance from "../../fetch/axios";
import { CartContext } from "../../context/CardContext";

const Component = () => {

  const navigate = useNavigate();
  const { loadCartFromLocalStorage } = useContext(CartContext);

  const googleLogin = useGoogleLogin({
    onSuccess: (response) => {
      axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${response.access_token}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${response.access_token}`
        },
      }).then((response) => {
        instance.post('users/social-login', {
          google_id: response.data.id,
          name: response.data.name,
          email: response.data.email,
          photo: response.data.picture,
        }).then((response) => {
          
          let user = {
            affiliation: response.data.affiliation,
            email: response.data.email,
            name:response.data.name,
            phone:response.data.phone,
            code_phone:response.data.code_phone
          }
          localStorage.setItem('user_arteonu', JSON.stringify(user));
          localStorage.setItem('token_arteonu', response.data.api_token);
          loadCartFromLocalStorage()
          navigate("/home");
        }).catch((error) => {
          console.log(error);
        });
      }).catch((error) => {
        console.log(error);
      });
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const facebookLogin = (response) => {
    if (response?.status !== 'unknown') {
      axios.post('users/social-login', {
        facebook_id: response.id,
        name: response.name,
        email: response.email,
        photo: response.picture?.data?.url,
      }).then((response) => {

        let user = {

          affiliation: response.data.affiliation,
          email: response.data.email,
          name:response.data.name,
          phone:response.data.phone,
          code_phone:response.data.code_phone
        }
        
        localStorage.setItem('user_arteonu', JSON.stringify(user));
        localStorage.setItem('token_arteonu', response.data.api_token);
        loadCartFromLocalStorage()

        navigate("/home");
      }).catch((error) => {
        console.error(error);
      });
    } else {
      console.error(response);
    }
  }

  return (
    <div className="GroupButton">
      <div className="ButtonLightMini" onClick={() => googleLogin()}>
        <span className="IconGoogle">
          <i className="icon-GoogleRed"></i>
          <i className="icon-GoogleYellow"></i>
          <i className="icon-GoogleGreen"></i>
          <i className="icon-GoogleBlue"></i>
        </span>
        Continuar con Google
      </div>
      <FacebookLogin
        appId={process.env.REACT_APP_FACEBOOK_APP_ID}
        autoLoad={false}
        callback={facebookLogin}
        fields={"name,email,picture"}
        render={renderProps => (
          <div className="ButtonLightMini" onClick={renderProps.onClick}>
            <i className="icon-Facebook" style={{color: "#1877f2"}}></i>
            Continuar con Facebook
          </div>
        )}/>
      <div className="ButtonLightMini" onClick={() => navigate("/login-email")}>
        <i className="icon-MailOpen"></i>
        Continuar con email
      </div>
    </div>
  );
};

export default Component;
