import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CartContext } from "../../context/CardContext";
import axios from "../../fetch/axios";
import { TokenContext } from "../../context/TokenContext";

const Component = () => {

  const navigate = useNavigate();
  const { getToken } = useContext(TokenContext);
  const { resetCart } = useContext(CartContext);
  const location = useLocation();
  const user =JSON.parse(localStorage.getItem('user_arteonu'))

  useEffect(() => {


    //SI ES STRIPE
    const paymentIntent = new URLSearchParams(location.search).get('payment_intent');

    const fetchStripe = async (payId) => {
      
      try {

        if (localStorage.getItem('cartId')) {

          const res = await axios.post('/cart/stripe/intent',
            {
              "cart_id": localStorage.getItem('cartId'),
              "payment_intent": payId
            },
            {
              headers: { 'Authorization': 'Bearer ' + getToken() }
            }

          );
          if (res.data) {

            localStorage.removeItem('cartId')
            localStorage.removeItem('stripe_arteonu')

            resetCart();

          } 

        }
      } catch (error) {
      }
    };

    if (paymentIntent) {
      
      localStorage.setItem('compra_arteonu',paymentIntent)
      
      fetchStripe(paymentIntent)
      
    }

    if (localStorage.getItem('stripe_arteonu') !== null) {
      fetchStripe(localStorage.getItem('stripe_arteonu'));
    }

    //SI ES PAYPAL
    const orderId = new URLSearchParams(location.search).get('token');

    const fetchPaypal = async (order_id) => {
      
      try {

        if (localStorage.getItem('cartId')) {

          const res = await axios.post('/cart/paypal/capture-order',
            {
              "cart_id": localStorage.getItem('cartId'),
              "order_id": order_id
            },
            {
              headers: { 'Authorization': 'Bearer ' + getToken() }
            }

          );
          if (res.data) {

            localStorage.setItem('cartId','')
            localStorage.removeItem('paypal_arteonu')

            resetCart();

          } 

        }
      } catch (error) {
      }
    };

    if (orderId) {
      
      localStorage.setItem('paypal_arteonu',orderId)
      
      fetchPaypal(orderId)
      
    }

    if (localStorage.getItem('paypal_arteonu') !== null) {
      fetchPaypal(localStorage.getItem('paypal_arteonu'));
    }

  
  }, [])

  return (
    <section className="CheckoutBox CompraExitosa">
      <article>
        <div className="StepCheckout">
          <div className="Selected"></div>
          <div className="Selected"></div>
          <div className="Selected"></div>
          <div className="Selected"></div>
        </div>
        <div className="icon-Check"></div>
        <h5>¡Compra exitosa!</h5>
        <b>Tu pago fue realizado con éxito</b>
        <div className="FontSmall">
          Hemos enviado un email a {user.email}
          <br /> con los detalles de tu compra.
        </div>
        <div className="Row">
          <div
            className="ButtonLightMedium"
            onClick={() => navigate("/mis-pedidos")}
          >
            VER MIS PEDIDOS
          </div>
          <div className="ButtonDarkMedium" onClick={() => navigate("/home")}>
            SEGUIR COMPRANDO
            <i className="icon-Check"></i>
          </div>
        </div>
      </article>
    </section>
  );
};

export default Component;
