import React, { useState } from "react";
import Colecciones from "./Colecciones";
import { useLocation, useNavigate } from "react-router-dom";

const Component = ({ title, databc = [] }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [products, setProducts] = useState(location.state?.products || []);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(location.state?.pages || 1);

  if (!location.state?.products?.length) {
    navigate('/sin-resultados');
    return null;
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
    window.scrollTo(0, 0);
  };

  return (
    <Colecciones 
      title={title} 
      coleccionesMenu="0" 
      databc={[location.state?.searchTerm || '']} 
      noposition 
      products={products}
      currentPage={currentPage}
      totalPages={totalPages}
      onPageChange={handlePageChange}
    />
  );
};

export default Component;
