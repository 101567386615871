import React from 'react';
const Error404 = () => {
	return (
		<>
			<section className='Error404'>
				<div className='icon-LinkBreak'></div>
				<em>404</em>
				<h5>Página no encontrada</h5>
			</section>
		</>
	);
};

export default Error404;