import React from 'react';
import PodriaInteresarte from './PodriaInteresarte';
import { useNavigate } from "react-router-dom";

const Component = () => {

	const navigate = useNavigate();

	return (
		<>
			<div className={`CategoryTitle PerfilSection`}>
			<ul className="Breadcrumb">
				<li onClick={() => { navigate('/home') }} style={{ cursor: "pointer" }}>Home</li>
			</ul>
			</div>
			<section className='ResultadosZero'>
				<i className='icon-Magnifying'></i>
				<div className='Ups'></div>
				<b>¡No hemos encontrado nada!</b>
				<p>No hemos encontrado nada relacionado con tu<br />búsqueda, intenta utilizar otros términos de búsqueda.</p>
			</section>
			<PodriaInteresarte />
		</>
	);
};

export default Component;