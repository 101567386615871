import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from "../fetch/axios";

export const TokenContext = createContext({
  getToken:() => {},
});

export const TokenProvider = ({ children }) => {
    
  const storedToken = localStorage.getItem('token_arteonu');

  const [token, setToken] = useState(storedToken ? storedToken : null);

  useEffect(() => {

    const checkAndRenewToken = async () => {
        if(token)
          await renewToken();  
    };

    const intervalId = setInterval(checkAndRenewToken,5* 60 * 1000); // renueva el token cada 5 minutos

    return () => clearInterval(intervalId);
    
  }, [token]); 

  const renewToken = async () => {
    try {
      const storedToken = localStorage.getItem('token_arteonu');

      const response = await axios.put('/users/refresh-token', {}, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${storedToken}`
        },
      });

      if (response.data.api_token) {
        setToken(response.data.api_token);
        localStorage.setItem('token_arteonu', response.data.api_token);
      }else{
        localStorage.removeItem('token_arteonu');
        localStorage.removeItem('user_arteonu');
        localStorage.removeItem('cart');
      }
    } catch (error) {
      localStorage.removeItem('token_arteonu');
      localStorage.removeItem('user_arteonu');
      localStorage.removeItem('cart');
      console.error('Error renovando token:', error);
    }
  };

  const getToken =  () => {
    return localStorage.getItem('token_arteonu');
  }
  return (
    <TokenContext.Provider value={{ token, renewToken,getToken }}>
      {children}
    </TokenContext.Provider>
  );
};

export const useToken = () => useContext(TokenContext);