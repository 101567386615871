import title from "../../assets/icons/terminosycondiciones.svg";
import React from "react";
import { useNavigate } from "react-router-dom";
import { BannerLegal } from "../../components/Images";
import { BannerLegalMov } from "../../components/Images";
const Component = () => {
  const navigate = useNavigate();
  return (
    <>
      <section className="ArticleStaticLegal ImageButton">
        <article className="ImageButtonBG" >
          <picture>
            <source media="(max-width:700px)" srcSet={BannerLegalMov} type="image/webp" />           
            <img src={BannerLegal} alt="" />
          </picture>
          <div>
            <div>
              <div className="ButtonContent ButtonDark">Terms of Service</div>
            </div>
          </div>
        </article>
        <article className="ArticleStaticContent">
          <div className="ArticleStaticContentText">
            <strong>Arte On U</strong><br /><br />
            <strong>Terms of Service</strong><br />
            <strong>Terms and Conditions</strong><br /><br />

            <p>If you require any more information or have any questions about our Terms and Conditions, please feel free to contact us.<br />
            These terms and conditions govern your use of this website; by using this website, you accept these terms and condition you Dons in full and without reservation. If you disagree with these terms and conditions or any part of these terms and conditions, you must not use this website.
            You must be at least 18 (eighteen) years of age to use this website. By using this website and by agreeing to these terms and conditions, you warrant and represent that you are at least 18 years of age.</p>

            <p>License to Use Website<br />
            Unless otherwise stated, Arte On U and/or its licensors own the intellectual property rights published on this website and materials used in this website. Subject to the license below, all these intellectual property rights are reserved.<br />
            You may view, download for caching purposes only, and print pages, files or other content from this website for your own personal use, subject to the restrictions set out and elsewhere in these terms and conditions.<br />
            You must not:<br />
            Republish material from this website in neither print nor digital media or documents (including republication on another website);<br />
            Sell, rent or sub-license material from this website;<br />
            Show any material from this website in public;<br />
            Reproduce, duplicate, copy or otherwise exploit material on this website for a commercial purpose;<br />
            Edit or otherwise modify any material on this website;<br />
            Redistribute material from this website- except for content specifically and expressly made available for redistribution; or republish or reproduce any part of this website through the use of iframes or screenscrapers.<br />
            Where content is specifically made available for redistribution, it may only be redistributed within your organization.</p>

            <p>Acceptable Use<br />
            You must not use this website in any way that causes, or may cause, damage to the website or impairment of the availability or accessibility of Arte On U or in any way which is unlawful illegal, fraudulent or harmful, or in connection with any unlawful, illegal, fraudulent or harmful purpose or activity.
            You must not use this website to copy, store, host, transmit, send, use, publish or distribute any material which consists of (or is linked to) any spyware, computer virus, Trojan horse, worm, keystroke, logger, rootkit or other malicious computer software.<br />
            You must not conduct any systematic or automated data collection activities on or in relation to this website without Arte On U's express written consent.<br />
            This includes:<br />
            Scraping, Data mining, Data extraction, Data harvesting, 'framing' (iframing), Article 'Spinning'.<br />
            You must not use this website or any part of it to transmit or send unsolicited commercial communications.<br />
            You must not use this website for any purposes related to marketing without the express written consent of Arte On U.</p>

            <p>Restricted Access<br />
            Access to certain areas of this website is restricted. Arte On U reserves the right to restrict access to certain areas of this website, or at our discretion, this entire website. Arte On U may change or modify this policy without notice.<br />
            If Arte On U provides you with a user ID and password to enable you to access restricted areas of this website or other content or services, you must ensure that the user ID and password are kept confidential. You alone are responsible for your password and user ID security.
            Arte On U may disable your user ID and password at Arte On U's sole discretion without notice or explanation.</p>

            <p>User Content<br />
            In these terms and conditions, "your user content" means material (including without limitation text, images, audio material, video material and audio-visual material) that you submit to this website , for whatever purpose.<br />
            You grant to Arte On U a worldwide, irrevocable, non-exclusive, royalty-free license to use, reproduce, adapt, publish, translate and distribute your user content in any existing or future media. You also grant to Arte On U the right to sub-license these rights, and the right to bring to action for infringement of these rights.<br />
            Your user content must not be illegal or unlawful, must not infringe any third party's legal rights, and must not be capable of giving rise of legal action whether against you or Arte On U or a third party (in each case under any applicable law).<br />
            You must not to submit any user content to this website that is or ever been the subject of any threatened or actual legal proceeding or other similar complaint.<br />
            Arte On U reserves the right to edit or remove any material submitted to this website, or stored on the server of Arte On U, or hosed or published upon this website.<br />
            Arte On U's rights under these terms and conditions in relation to user content, Arte On U does not undertake to monitor the submission of such content to, or the publication of such content on, this website.</p>

            <p>No Warranties<br />
            This website is provided "as it is" without any representation or warranties, express of implied. Arte On U  makes no representations or warranties in relation to this website or the information and material provided on this website.<br />
            Without prejudice to the generality of the foregoing paragraph, Arte On U not warrant that:<br />
            This website will be constantly available, or available at all; or the information on this website is complete, true, accurate or non-misleading.<br />
            Nothing on this website constitutes, or is meant to constitute, advice of any kind. If you require advice in relation to any legal, financial or medical matter you should consult an appropriate professional.</p>

            <p>Limitation of Liability<br />
            Arte On U will not be liable to you (whether under the law of contact, the law of torts or otherwise) in relation to the contents of, or use of, or otherwise in connection with this website:<br />
            To the extent this website is provided free-of-charge, for any direct loss; for any indirect, special or consequential loss; or for any business losses, loss of revenue, income, profits or anticipated savings, loss of contracts or business relationships, loss of reputation or goodwill, or loss or corruption of information or data.<br />
            These limitations of liability apply even Arte On U has been expressly advised of the potential loss.</p>

            <p>Exceptions<br />
            Nothing in this website disclaimer will be exclude or limit any warranty implied by law that it would be unlawful to exclude or limit; and nothing in this website disclaimer will exclude or limit the liability of Arte On U in respect of any:<br />
            Death or personal injury caused by negligence of Arte On U or its agents, employees or shareholders/owners;<br />
            Fraud or fraudulent misrepresentation on the part of Arte On U; or matter which it would be illegal or unlawful for Arte On U to exclude or limit, or to attempt or purport to exclude or limit, its liability.</p>

            <p>Reasonableness<br />
            By using this website, you agree that the exclusions and limitations of liability set out in this website disclaimer are reasonable.<br />
            If you don't think they are reasonable, you must not use this website.</p>

            <p>Other Parties<br />
            You accept that, as a limited entity, Arte On U has an interest in limiting the personal liability of its officers and employees. You agree that you will not bring any claim personally against Arte On U's officers or employees in respect of any losses you suffer in connection with this website.<br />
            Without prejudice to the foregoing paragraph, you agree that the limitations of warranties and liability set out in this website disclaimer will protect Arte On U's officers, employees, agents, subsidiaries, successors, assigns and sub-contractors as well as Arte On U.</p>

            <p>Unenforceable Provisions<br />
            If any provision of this website disclaimer is, or is found to be, unenforceable under applicable law, that will not affect the enforceability of the other provisions of this website disclaimer.</p>

            <p>Indemnity<br />
            You hereby indemnify Arte On U and undertake to keep Arte On U indemnified against any losses, damages, costs, liabilities and expenses (including without limitation legal expenses and any amount paid by Arte On U to a third party in settlement of a claim of dispute on the advice of Arte On U's legal advisers) incurred od suffered by Arte On U rising out of any breach by you of any provision of these terms and conditions, or arising out of any claim that you have breached any provision of these terms and conditions.</p>

            <p>Breaches of These Terms and Conditions<br />
            Without prejudice to Arte On U's other rights under these terms and conditions, if you breach these terms and conditions in any way, Arte On U may take such actions as<br />
            Arte On U deems appropriate to deal with the breach, including suspending your access to the website, prohibiting you from accessing the website, blocking computers using your IP address from accessing the website, contacting your internet service provider to request that they block your access to the website and/or bringing court proceeding against you.</p>

            <p>Variation<br />
            Arte On U may revise these terms and conditions from time-to-time. Revised terms and conditions will apply to the use of this website from the date of the publication of the revised terms and conditions on this website. Please check this page regularly to ensure you are familiar with the current version.</p>

            <p>Assignment<br />
            Arte On U may transfer, sub-contract or otherwise deal with Arte On U's rights and/or obligations under these terms and conditions without notifying you or obtaining your consent.<br />
            You may not transfer, sub-contract or otherwise deal with your rights and/or obligations under these terms and conditions.</p>

            <p>Severability<br />
            If a provision of these terms and conditions is determined by any court or other competent authority to be unlawful and/or unenforceable, the other provisions will continue in effect. If any unlawful and/or unenforceable provision would be lawful or enforceable if part of it were deleted, that part will be deemed to be deleted, and the rest of the provision will continue in effect.</p>

            <p>Entire Agreement<br />
            These terms and conditions, together with Arte On U's Privacy Policy constitute the entire agreement between you and Arte On U in relation to your use of this website, and supersede all previous agreements in respect of your use of this website.</p>
            <br /><br />
            <strong>Arte On U</strong><br />
            <strong>Términos de servicio</strong><br />
            <strong>Términos y condiciones</strong><br /><br />

            <p>Si necesita más información o tiene alguna pregunta sobre nuestros Términos y Condiciones, no dude en contactarnos.<br />
            Estos términos y condiciones rigen el uso de este sitio web; al utilizar este sitio web, acepta estos términos y condiciones en su totalidad y sin reservas. Si no está de acuerdo con estos términos y condiciones o con cualquier parte de estos términos y condiciones, no debe utilizar este sitio web.
            Debe tener al menos 18 (dieciocho) años de edad para utilizar este sitio web. Al utilizar este sitio web y aceptar estos términos y condiciones, usted garantiza y declara que tiene al menos 18 años de edad.</p>

            <p>Licencia para usar el sitio web<br />
            A menos que se indique lo contrario, Arte On U y/o sus licenciantes poseen los derechos de propiedad intelectual publicados en este sitio web y los materiales utilizados en este sitio web. Sujeto a la licencia a continuación, todos estos derechos de propiedad intelectual están reservados.<br />
            Puede ver, descargar únicamente con fines de almacenamiento e imprimir páginas, archivos u otro contenido de este sitio web para su uso personal, sujeto a las restricciones establecidas y en otras partes de estos términos y condiciones.<br />
            No debes:<br />
            Volver a publicar material de este sitio web en documentos impresos o digitales (incluida la republicación en otro sitio web);<br />
            Vender, alquilar o sublicenciar material de este sitio web;<br />
            Mostrar cualquier material de este sitio web en público;<br />
            Reproducir, duplicar, copiar o explotar de otro modo el material de este sitio web con fines comerciales;<br />
            Editar o modificar cualquier material de este sitio web;<br />
            Redistribuir material de este sitio web, excepto el contenido que específica y expresamente esté realizado y dispuesto para su redistribución; ni volver a publicar o reproducir cualquier parte de este sitio web mediante el uso de iframes o screenscrapers.<br />
            Cuando el contenido esté disponible específicamente para su redistribución, solo podrá redistribuirse dentro de su organización.</p>

            <p>Uso Aceptable<br />
            No debe utilizar este sitio web de ninguna manera que cause, o pueda causar daño al sitio web o deterioro de la disponibilidad o accesibilidad de Arte On U o de cualquier manera que sea ilegal, fraudulenta o dañina, o en conexión con cualquier actividad ilegal. , finalidad o actividad ilegal, fraudulenta o nociva.<br />
            No debe utilizar este sitio web para copiar, almacenar, alojar, transmitir, enviar, usar, publicar o distribuir cualquier material que consista en (o esté vinculado a) cualquier software espía, virus informático, "Trojan horse", "worm", "keystroke", "logger", "rootkit" u otro software informático malicioso.<br />
            No debe realizar ninguna actividad de recopilación de datos sistemática o automatizada en este sitio web o en relación con él sin el consentimiento expreso por escrito de Arte On U.<br />
            Esto incluye:<br />
            Scraping, Procesamiento de datos, Extracción de datos, Recolección de datos, 'framing' (iframes), Article 'Spinning'.<br />
            No debe utilizar este sitio web ni ninguna parte del mismo para transmitir o enviar comunicaciones comerciales no solicitadas.<br />
            No debe utilizar este sitio web para ningún propósito relacionado con el marketing sin el consentimiento expreso por escrito de Arte On U.</p>

            <p>Acceso restringido<br />
            El acceso a determinadas áreas de este sitio web está restringido. Arte On U se reserva el derecho de restringir el acceso a ciertas áreas de este sitio web, a nuestra discreción, y/o a todo este sitio web. Arte On U puede cambiar o modificar esta política sin previo aviso.<br />
            Si Arte On U le proporciona una identificación de usuario y una contraseña para permitirle acceder a áreas restringidas de este sitio web u otros contenidos o servicios, debe asegurarse de que la identificación de usuario y la contraseña se mantengan confidenciales. Usted es el único responsable de la seguridad de su contraseña e identificación de usuario.<br />
            Arte On U puede desactivar su ID de usuario y contraseña a su entera discreción sin previo aviso ni explicación.</p>

            <p>Contenido del usuario<br />
            En estos términos y condiciones, "su contenido de usuario" significa el material (incluido, entre otros, texto, imágenes, material de audio, material de video y material audiovisual) que usted envía a este sitio web, para cualquier propósito.<br />
            Usted otorga a Arte On U una licencia internacional, irrevocable, no exclusiva y libre de regalías para usar, reproducir, adaptar, publicar, traducir y distribuir su contenido de usuario en cualquier medio existente o futuro. También otorga a Arte On U el derecho a sublicenciar estos derechos y el derecho a emprender acciones por infracción de estos derechos.<br />
            Su contenido de usuario no debe ser ilegal o fuera de la ley, no debe infringir los derechos legales de ningún tercero y no debe ser capaz de dar lugar a acciones legales ya sea contra usted o Arte On U o un tercero (en cada caso bajo cualquier ley aplicable).<br />
            No debe enviar ningún contenido de usuario a este sitio web que sea o haya sido objeto de cualquier procedimiento legal real o amenazado u otra queja similar.<br />
            Arte On U se reserva el derecho de editar o eliminar cualquier material enviado a este sitio web, o almacenado en el servidor de Arte On U, o almacenado o publicado en este sitio web.<br />
            Los derechos de Arte On U bajo estos términos y condiciones en relación con el contenido del usuario, Arte On U no se compromete a monitorear el envío de dicho contenido o la publicación de dicho contenido en este sitio web.</p>

            <p>Sin garantías<br />
            Este sitio web se proporciona "tal cual es" sin ninguna representación ni garantía, expresa o implícita. Arte On U no hace ninguna declaración ni garantía en relación con este sitio web o la información y el material proporcionados en este sitio web.<br />
            Sin perjuicio de la generalidad del párrafo anterior, Arte On U no garantiza que:<br />
            Este sitio web estará constantemente disponible o estará disponible en absoluto; o la información contenida en este sitio web es completa, verdadera, precisa o no engañosa.<br />
            Nada en este sitio web constituye, ni pretende constituir, asesoramiento de ningún tipo. Si necesita asesoramiento en relación con cualquier asunto legal, financiero o médico debe consultar a un profesional adecuado.</p>

            <p>Limitación de Responsabilidades<br />
            Arte On U no será responsable ante usted (ya sea según la ley de contratos, la ley de daños o de otro tipo) en relación con el contenido, el uso o de otro modo en conexión con este sitio web:<br />
            En la medida en que este sitio web se proporcione de forma gratuita, por cualquier pérdida directa; por cualquier pérdida indirecta, especial o consecuente; o por cualquier pérdida comercial, pérdida de ingresos, ganancias o ahorros anticipados, pérdida de contratos o relaciones comerciales, pérdida de reputación o buena voluntad, o pérdida o corrupción de información o datos.<br />
            Estas limitaciones de responsabilidad se aplican incluso si Arte On U ha sido advertido expresamente de la posible pérdida.</p>

            <p>Excepciones<br />
            Nada en este descargo de responsabilidades de este sitio web excluirá o limitará cualquier garantía implícita por ley que sería ilegal excluir o limitar; y nada en este descargo de responsabilidades de este sitio web excluirá o limitará la responsabilidad de Arte On U con respecto a cualquier:<br />
            Muerte o lesión personal causada por negligencia de Arte On U o sus agentes, empleados o accionistas/propietarios;<br />
            Fraude o tergiversación fraudulenta por parte de Arte On U; o asunto en el que sería ilegal o ilícito que Arte On U excluyera o limitara, o intentara o pretendiera excluir o limitar, su responsabilidad.</p>

            <p>Sensatez<br />
            Al utilizar este sitio web, usted acepta que las exclusiones y limitaciones de responsabilidades establecidas en este descargo de responsabilidades de este sitio web son razonables.<br />
            Si no cree que sean razonables, no debe utilizar este sitio web.</p>

            <p>Otros partidos<br />
            Usted acepta que, como entidad limitada, Arte On U tiene interés en limitar las responsabilidades personales de sus funcionarios y empleados. Usted acepta que no presentará ningún reclamo personalmente contra los funcionarios o empleados de Arte On U con respecto a cualquier pérdida que sufra en relación con este sitio web.<br />
            Sin perjuicio del párrafo anterior, usted acepta que las limitaciones de garantías y responsabilidad establecidas en este descargo de responsabilidad de este sitio web protegerán a los funcionarios, empleados, agentes, subsidiarias, sucesores, cesionarios y subcontratistas de Arte On U, así como a Arte On U.</p>

            <p>Disposiciones inaplicables<br />
            Si alguna disposición de este descargo de responsabilidad de este sitio web es, o se determina que es, inaplicable según la ley aplicable, eso no afectará la aplicabilidad de las demás disposiciones de este descargo de responsabilidades de este sitio web.</p>

            <p>Indemnidad<br />
            Por la presente, indemniza a Arte On U y se compromete a mantener a Arte On U indemnizado contra cualquier pérdida, daño, costo, responsabilidad y gasto (incluidos, entre otros, gastos legales y cualquier monto pagado por Arte On U a un tercero en la resolución de un reclamo de disputa, con el asesoramiento de los asesores legales de Arte On U) incurridos o sufridos por Arte On U como resultado de cualquier incumplimiento por su parte de cualquier disposición de estos términos y condiciones, o que surja de cualquier reclamo de que usted ha violado cualquier disposición de estos términos y condiciones.</p>

            <p>Infracciones de estos términos y condiciones<br />
            Sin perjuicio de los demás derechos de Arte On U bajo estos términos y condiciones, si usted incumple estos términos y condiciones de alguna manera, Arte On U puede tomar las acciones que Arte On U considera apropiadas para abordar la infracción, incluida la suspensión de su acceso al sitio web, prohibirle el acceso al sitio web, bloquear el acceso de las computadoras que usan su dirección IP al sitio web, comunicarse con su proveedor de servicios de Internet para solicitar que bloquee su acceso a el sitio web y/o iniciar un procedimiento judicial en su contra.</p>

            <p>Variación<br />
            Arte On U puede revisar estos términos y condiciones de vez en cuando. Los términos y condiciones revisados ​​se aplicarán al uso de este sitio web a partir de la fecha de publicación de los términos y condiciones revisados ​​en este sitio web. Por favor revise esta página periódicamente para asegurarse de que está familiarizado con la versión actual.</p>

            <p>Asignación<br />
            Arte On U puede transferir, subcontratar o negociar de otro modo los derechos y/u obligaciones de Arte On U bajo estos términos y condiciones sin notificárselo ni obtener su consentimiento.<br />
            Usted no puede transferir, subcontratar ni negociar de otro modo sus derechos y/u obligaciones según estos términos y condiciones.</p>

            <p>Divisibilidad<br />
            Si un tribunal u otra autoridad competente determina que una disposición de estos términos y condiciones es ilegal y/o inaplicable, las demás disposiciones seguirán en vigor. Si alguna disposición ilegal y/o inaplicable fuera legal o ejecutable si se eliminara parte de ella, esa parte se considerará eliminada y el resto de la disposición seguirá en vigor.</p>

            <p>Acuerdo completo<br />
            Estos términos y condiciones, junto con la Política de privacidad de Arte On U, constituyen el acuerdo completo entre usted y Arte On U en relación con su uso de este sitio web, y reemplazan todos los acuerdos anteriores con respecto a su uso de este sitio web.</p><br /><br />
          </div>
          <div className="Contactanos">
            <div>
              <b>¿Tienes dudas?</b>
            </div>
            <div>¡Ponte en contacto con nosotros!</div>
            <div
              className="ButtonContent ButtonDark"
              onClick={() => navigate("/contacto")}
            >
              CONTACT US
            </div>
          </div>
        </article>
      </section>
    </>
  );
};

export default Component;
