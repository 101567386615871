import React from 'react';

const ItemDireccion = ({ direccion, onSelect,handleEditar }) => {
  return (
    <label className='ItemDireccion'>
      <input 
        type='radio' 
        name='direccionenvio'
        onChange={() => onSelect(direccion.id)}
      />
      <div className={direccion.selected ? 'Selected' : ''}>
        <div>{direccion.line}, {direccion.country_name} {direccion.city}, {direccion.postal_code}</div>
        <div>{direccion.details}</div>
        <div className='EditarDireccion' style={{ cursor:"pointer" }}
		onClick={() => handleEditar(direccion)}
		>Editar</div>
      </div>
    </label>
  );
};

export default ItemDireccion;
