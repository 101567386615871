import { useSnackbar } from "notistack";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../fetch/axios";
import { TokenContext } from "../../context/TokenContext";

const AgregarEditarDireccionEnvio = () => {

  const { idDireccion } = useParams();
  const [id, setId] = useState(idDireccion ? idDireccion : null);
  const [street, setStreet] = useState(null);
  const [cp, setCp] = useState(null);
  const [city, setCity] = useState(null);
  const [country, setCountry] = useState(142);
  const [detail, setDetail] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const { getToken } = useContext(TokenContext);

  const navigate = useNavigate();

  const guardar = async (type=1) => {

    if (!street) {
      enqueueSnackbar("El campo de dirección es obligatorio", { variant: "error" });
      return;
    }
    if (!cp || isNaN(cp)) {
      enqueueSnackbar("El código postal debe ser un número entero", { variant: "error" });
      return;
    }
    if (!city) {
      enqueueSnackbar("El campo de ciudad es obligatorio", { variant: "error" });
      return;
    }
    try {
        await axios[type == 1 ? 'post' : 'put'](
          type == 1 ? '/addresses/create' : '/addresses/update',
          {
            id: id,
            country_id: country,
            line: street,
            postal_code: parseInt(cp),
            city: city,
            details: detail
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
            }
          }
        );
        enqueueSnackbar("Dirección guardada con éxito", { variant: "success" });
        navigate('/mi-perfil');
      } catch (error) {
        console.error('Error al guardar la dirección:', error);
        enqueueSnackbar("Ocurrió un error al guardar la dirección", { variant: "error" });
      }
      

  };

  const eliminar = async () => {

    try {
      await axios.delete('/addresses/remove?id='+idDireccion,{
        headers: {'Authorization': 'Bearer ' + getToken()}
      });
      
        enqueueSnackbar("Direccion eliminada con éxito", { variant: "error" });
        navigate('/mi-perfil')
      
    } catch (error) {
      enqueueSnackbar("No se puede eliminar la direccioón por los momentos", { variant: "error" });
      navigate('/mi-perfil')
    }

  };

  useEffect(()=>{

    if(idDireccion){

      const fetchDireccion = async () => {

        const user = JSON.parse(localStorage.getItem('user_arteonu'));
        try {
          const res = await axios.get('/addresses/detail?id='+idDireccion,{
            headers: {'Authorization': 'Bearer ' + getToken()}
          });
          const dir = res.data;
          if(dir.email==user.email){
            setId(dir.id)
            setStreet(dir.line)
            setCp(dir.postal_code)
            setDetail(dir.details)
            setCity(dir.city)
          }else{
            enqueueSnackbar("Direccion no disponible", { variant: "error" });
            navigate('/mi-perfil')
          }
        } catch (error) {
          enqueueSnackbar("Direccion no disponible", { variant: "error" });
          navigate('/mi-perfil')
        }
      };

      fetchDireccion();
    }

  },[])

  return (
    <>
      <div className="PerfilContent">

        <div className={`CategoryTitle PerfilSection`}>
          <ul className="Breadcrumb">
            <li onClick={() => { navigate('/home') }} style={{ cursor: "pointer" }}>Home</li>
            <li onClick={() => { navigate('/mi-perfil') }} style={{ cursor: "pointer" }}>Mi Perfil</li>
            <li>{idDireccion ? 'Editar' : 'Agregar'} Dirección</li>
          </ul>
        </div>

        <div className="grid-title" style={{ display: 'block' }}>

          <h2>{idDireccion ? 'Editar dirección de envío' : 'Agregar dirección de envío'}</h2>

        </div>

        <hr />

        <section className="DireccionEnvio">

          <div className="form-box">

            <label className="FormData">

              <em className="RequiredData">Calle, Número, Apartamento</em>

              <div className="FormInputLight">
                <input placeholder="Calle, Número, Apartamento" onChange={(e) => { setStreet(e.target.value) }} value={street} />
              </div>

            </label>

            <div className="form-row">

              <label className="FormData">
                <em className="RequiredData">Código Postal</em>
                <div className="FormInputLight">
                  <input placeholder="Código Postal" onChange={(e) => { setCp(e.target.value) }}  value={cp}/>
                </div>
              </label>


              <label className="FormData">
                <em className="RequiredData">Ciudad</em>
                <div className="FormInputLight">
                  <input placeholder="Ciudad" onChange={(e) => { setCity(e.target.value) }} value={city} />
                </div>
              </label>

            </div>

            <label className="FormData">
              <em className="RequiredData">Pais</em>
              <div className="FormInputLight">
                <select placeholder="Email" onChange={(e) => { setCountry(e.target.value) }} value={country}>
                  <option value="124" selected>
                    México
                  </option>
                  <option value="233">USA</option>
                </select>
                <i className="InputIcon icon-Select"></i>
              </div>
            </label>


            <label className="FormData">
              <em className="RequiredData">Detalles</em>
              <div className="FormInputLight">
                <textarea placeholder="Agrega detalles de la dirección" onChange={(e) => { setDetail(e.target.value) }} value={detail}></textarea>
              </div>
            </label>

            <button className="ButtonLightMedium" onClick={() => { guardar(idDireccion ? 2 : 1) }}>
              {idDireccion ? 'ACTUALIZAR DATOS' : 'GUARDAR DATOS'} 
            </button>

            {idDireccion && <button className="DeleteAddressButton" style={{ cursor:'pointer' }} onClick={()=>{eliminar()}}>Eliminar esta dirección de envío</button>}


          </div>

        </section>

      </div>
    </>
  );

};

export default AgregarEditarDireccionEnvio;
