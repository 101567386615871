import React, { useContext, useEffect, useRef, useState } from "react";
import MigajasCategorias from "../../components/MigajasCategorias";
import { useParams } from "react-router-dom";
import axios from '../../fetch/axios';
// import ProductoResultado from "./ProductoResultado";
import PaginadoComponent from "./PaginadoComponent";
import { LanguageCurrencyContext } from "../../context/LanguageCurrencyContext";
import { TokenContext } from "../../context/TokenContext";
// import LoadingCard from "./LoadingCard";

const ResultadosSubcategorias = () => {

  const coleccionPosition = useRef(null);
  const [loading, setLoading] = useState(true);
  const [category, setCategory] = useState(null);
  const [subcategory, setSubategory] = useState(null);
  const [productos, setProductos] = useState([]);
  const [colecciones, setColecciones] = useState([]);
  const [posicion, setPosicion] = useState(null);
  const { categoria, subcategoria } = useParams();
  const { lang,currency } = useContext(LanguageCurrencyContext);
  const { getToken } = useContext(TokenContext);

  const fetchCategory = async () => {
    try {
      const responseca = await axios.get(`/categories/detail?uri=${categoria}` + `&lang=`+lang+ `&currency=`+currency);
      setCategory(responseca.data);

      // Recorrer el array de subcategorias y encontrar la coincidencia
      const matchingSubcategory = responseca.data.subcategories.find(item => item.uri === subcategoria);

      // Si se encuentra una coincidencia, actualizar subcategoryName
      if (matchingSubcategory) {
        setSubategory(matchingSubcategory.name);
      } else {
        // Si no se encuentra una coincidencia, establecer subcategoryName en null o un valor por defecto
        setSubategory(null);
      }
    } catch (error) {
      console.error('Error fetching category details', error);
    }
  };

  const fetchColecciones = async () => {
    try {
      const responseca = await axios.get(`/collections/list` + `?lang=`+lang+ `&currency=`+currency);
      setColecciones(responseca.data);
    } catch (error) {
      console.error('Error fetching category details', error);
    }
  };

  const fetchProductos = async (colelccion) => {
    let url = `/products/search?subcategory_uri=` + subcategoria + `&lang=`+lang+ `&currency=`+currency ;

    if (colelccion !== null && colelccion !== undefined) {
      url += `&collection_id=${encodeURIComponent(colelccion)}` + `&lang=`+lang+ `&currency=`+currency;
    }

    try {
      const response = await axios.get(url,{
        headers: { 'Authorization': 'Bearer ' + getToken() }
      });
      // Procesa la respuesta aquí
      setProductos(response.data.products);
      // setProductos(duplicateProducts(response.data.products,2,31));

      setLoading(false)

    } catch (error) {
      console.error('Error al obtener los productos:', error);
      throw error;
    }
  };


  
  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        fetchCategory(),
        fetchColecciones(),
        fetchProductos()
      ]);
    };

    fetchData();
  }, [lang,currency])


  function SetPositionColeccion(event) {
    const pos = ["Vertical", "Horizontal"];
    pos[2] = event.target.dataset.n.split(",");
    event.target.parentNode
      .querySelector(`.icon-${pos[pos[2][0]]}`)
      .classList.add("Selected");
    event.target.parentNode
      .querySelector(`.icon-${pos[pos[2][1]]}`)
      .classList.remove("Selected");
    coleccionPosition.current.classList.toggle("ColeccionHorizontal");
    coleccionPosition.current.classList.toggle("ColeccionVertical");
  }


  return (
    <>
      <MigajasCategorias 
        nivel1={category ? category.name : 'loading...'} 
        nivel2={subcategory ? subcategory : ''} 
        />


      <section className="ColeccionMenu">

        {colecciones && (
          <div>
            <p>Colecciones:</p>
            <ul>
            <li className={`${!posicion ? 'Selected' : ''}`} 
            onClick={() => {
              setPosicion(false);
              fetchProductos();
              setLoading(true);

            }}
            >Todas</li>
            {colecciones.map((coleccion) => (
                <li 
                key={coleccion.id} 
                className={`${posicion==coleccion.id ? 'Selected' : ''}`} 
                onClick={() => {
                  setPosicion(coleccion.id);
                  fetchProductos(coleccion.id);
                  setLoading(true);
                  
                }}
                >
                  {coleccion.name}</li>
              ))}
            </ul>
          </div>
        )}

        <div>
          <span
            className="icon-Vertical"
            data-n="0,1"
            onClick={SetPositionColeccion}
          >
            Vertical
          </span>
          <span
            className="icon-Horizontal Selected"
            data-n="1,0"
            onClick={SetPositionColeccion}
          >
            Horizontal
          </span>
        </div>
      </section>

      <PaginadoComponent productos={productos} loading={loading} coleccionPosition={coleccionPosition} />

      {/* <section
        className="ColeccionItems ColeccionVertical"
        ref={coleccionPosition}
      >
        {!loading ? productos.map((producto) => (
          <ProductoResultado
            key={producto.id || producto._id}
            producto={producto}
          />))
          :
          <>
            {<LoadingCard/>}
          </>
        }

      </section>

      <section className="ColeccionPagination">
        <div className="icon-CaretLeft"></div>
        <ul>
        <li>1</li>
        <li>2</li>
        <li className="Selected">3</li>
        <li>4</li>
        <li>5</li>
      </ul>
        <div className="icon-CaretRight"></div>
      </section> */}
    </>
  );

};

export default ResultadosSubcategorias;
